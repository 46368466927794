import { useEffect } from "react";
import { Tooltip } from 'bootstrap';
import { capitalizeFirstLetter, assert, arrayDedupeInplace } from '../common/utility';

import useVoice from "../hooks/voice.hook";

function ChineseWord({word, showStats, showDefs, capitalize, dontPad, forceStage, markWrong}) {
  // initialize tooltips - must call this code for tooltips to show up
  useEffect(() => {
    const tooltipTriggerList = document.querySelectorAll('[data-bs-toggle="tooltip"]');
    const tooltips = [...tooltipTriggerList].map(tooltipTriggerEl => new Tooltip(tooltipTriggerEl));
    return ()=>{ // run this on unmount
      tooltips.forEach(tooltip=>tooltip.dispose());
    };
  }, []); // only run on mount

  if(!word)
    return;
  //console.log('ChineseWord', word);

  if(!word.rawText && !word.pinyinBreakdown?.length)
    word.rawText = word.toneless; // handle things like {userFName}
  if(!!word.rawText)
    return (
      <span class="fw-medium">
        {word.rawText}{dontPad?'':' '}
      </span>
    );
  
  // get word spelling given stage
  var stage = forceStage;
  var wordJsx = null;
  if(stage === undefined)
    stage = 0;
  switch(stage) {
  case 0:
    function getTonelessParts(word) {
      const tonelessParts = word.pinyinBreakdown.map(syllable=>(syllable.consonantPart+syllable.vowelPart))
        .map(str => str.replaceAll('v','u')) // use 'u' for beginners, not 'ü'
      if(word.isProper || capitalize)
        tonelessParts[0] = capitalizeFirstLetter(tonelessParts[0]);
      return tonelessParts;
    }
    const tonelessParts = getTonelessParts(word);
    wordJsx = tonelessParts.map((part, index)=>{
      if(index<(tonelessParts.length-1))
        return <>{part}<span class={markWrong?'text-danger text-opacity-50':'chinese-dot'}>·</span></>;
      else
        return <>{part}</>;
    });
    break;
  case 1:
    wordJsx = word.tones.replaceAll('v','u');
    if(word.isProper || capitalize)
      wordJsx = capitalizeFirstLetter(wordJsx);
    break;
  case 2:
    wordJsx = word.characters;    
    break;
  default:
    assert(false, "Invalid stage specified for ChineseWord");
  }
  
  // include stats or defs
  var stats = '';
  if(showStats && !!word.seen) {
    stats = `Seen: ${word.seen}<br/>Right: ${word.correct}`;
  }
  var defs = '';
  if(showDefs && word.defs.length>0) {
    defs = `<div class="mb-2 fw-bold">${capitalizeFirstLetter(word.defs.map(d=>d.english).join(', '))}</div>`;
  }
  
  // tooltip props
  const tooltipProps = {};
  if(defs !== '' || word.defs.length>0 || stats!=='') {
    tooltipProps[`data-bs-toggle`] = 'tooltip';
    tooltipProps[`data-bs-placement`] = 'bottom';
    tooltipProps[`data-bs-html`] = 'true';
    tooltipProps[`data-bs-title`] = `${defs}<div class="mb-2 ${!defs?'fw-bold':''}">${arrayDedupeInplace(word.defs.map(d=>d.posName)).join(', ')}</div>${stats}`;
  }
  
  // return word
  return (
    <span class={markWrong?'text-danger':''} style={{cursor:'pointer'}} {...tooltipProps}>
      {wordJsx}{dontPad?'':' '}
    </span>
  );
}

export default function ChinesePhrase({words, showStats, showDefs, capitalize, forceStage, markWrong, user}) {
  const voice = useVoice(user);

  // audio
  function playAudio() {
    voice.speak(words.map(w=>w.characters).join(''));
  }

  const jsx = [];
  words.forEach((word, index)=>{
    jsx.push(<ChineseWord word={word} showStats={showStats} showDefs={showDefs} capitalize={capitalize && !index} dontPad={index===(words.length-1)} forceStage={forceStage} markWrong={markWrong?.includes(word.wordId)} />);
  });
  return (<span onClick={playAudio}>
    {jsx}
  </span>);
}
