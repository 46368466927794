import { useState } from "react";
import useTimer from "../hooks/timer.hook";

import { ThreeDots } from 'react-loader-spinner'

export default function Loading() {
  const timer = useTimer();
  const [ show, setShow ] = useState(false);
  
  timer.fn(() => {
    setShow(true);
  }, 10); // this can be brief since it's fading in
  
  return (
    <div class="p-5 text-center">
      <div class="spinner-container d-inline-block" style={{width:80,height:80,opacity:show?1:0}}>
        <ThreeDots
          visible={true}
          height="80"
          width="80"
          color="gray"
          radius="9"
          ariaLabel="three-dots-loading"
          wrapperStyle={{}}
          wrapperClass=""
          />
      </div>
    </div>
  );
}