import { assert, saveUserPrefs } from '../common/utility';

// constants
const volumeMap = {
  mute:0,
  low:0.5,
  high:1,
};
/*const rateMap = {
  1:0.1,
  2:0.3,
  3:0.6,
  4:1,
  5:1.3,
};*/

// load synth
const synth = window.speechSynthesis;
synth.cancel(); // HACK - fixes (internal?) bug where sometimes it decides to stop doing synth

// hook
export default function useVoice(user) {
  let volume = user?.audioVolume ?? 'low';
  //const [ rate, setRate ] = useLocalStorage("audioRate", 1); // 1-5

  // voices
  const chineseVoices = synth.getVoices().filter(v=>v.lang==='zh-CN');
  function flattenVoiceURI(voice) {
    return voice.voiceURI.split(' ')[0].toLowerCase();
  }
  function getVoiceForName(name) {
    return chineseVoices.find(v=>flattenVoiceURI(v)===name);
  }
  const voiceOptions = chineseVoices.map(v=>flattenVoiceURI(v));

  // helpers
  function getVolumeValue() {
    return volumeMap[volume];
  }

  // return hook
  return {
    incVolume: (setUser)=>{
      var newVolume;
      switch(volume) {
        case 'mute':
          newVolume = 'low';
          break;
        case 'low':
          newVolume = 'high';
          break;
        case 'high':
          newVolume = 'mute';
          break;
        default:
          assert(false);
      }
      saveUserPrefs({ audioVolume:newVolume }, setUser, ()=>{
        volume = newVolume;
      })
    },
    getVolume: ()=>{
      return volume;
    },
    getVolumeValue,
    speak: (text, lang='chinese')=>{
      if(volume==='mute')
        return;
      const utterance = new SpeechSynthesisUtterance(text.toLowerCase());
      utterance.rate = (lang==='chinese') ? 0.1 : 0.3;
      //utterance.rate = rateMap[rate];
      utterance.volume = getVolumeValue();
      let voice;
      if(lang==='chinese')
        voice = user.audioVoice ? getVoiceForName(user.audioVoice) : chineseVoices[0];
      else
        voice = synth.getVoices().find(v=>v.voiceURI==='Samantha'); // en-US
      utterance.voice = voice;
      synth.speak(utterance);
    },
    /*setRate: (newRate)=>{
      const options = [1,2,3,4,5];
      assert(options.includes(newRate));
      setRate(newRate);
    },*/
    
    getVoiceOptions: (lang) =>{
      assert(lang==='chinese');
      return voiceOptions;
    },
    playSample: (optionName) =>{
      const voice = getVoiceForName(optionName);
      assert(voice);
      const utterance = new SpeechSynthesisUtterance('你好');
      utterance.rate = 0.1;
      utterance.volume = 1;
      utterance.voice = voice;
      synth.speak(utterance);
    },
  };
}