import { useEffect } from "react";
import { Link } from "react-router-dom";

export default function Pricing() {
  useEffect(() => {
    window.scrollTo({top: 0, behavior: 'instant'});
  }); // run ever render

  return (
    <div class="mt-4">
      <div class="p-3 pb-md-4 mx-auto text-center">
        <h1 class="display-4 fw-normal mb-3">Pricing</h1>
      </div>
      <div class="fs-5 text-muted lh-lg text-center mb-5">
        We have spent countless hours building and maintaining Easy Chinese. We hope you love it and would appreciate your support! If you&apos;re just getting started, feel free to enjoy the free tier first
      </div>
      <main>
        <div class="row mb-3 text-center justify-content-center">
          <div class="col-5">
            <div class="card mb-4 rounded-3 shadow-sm">
              <div class="card-header py-3">
                <h4 class="my-0 fw-normal">Free</h4>
              </div>
              <div class="card-body">
                <h1 class="card-title pricing-card-title">$0<small class="text-muted fw-light">/mo</small></h1>
                <ul class="list-unstyled mt-3 mb-4">
                  <li>200 words</li>
                  <li>10 grammars</li>
                  <li>2 daily lessons</li>
                </ul>
                <Link to='/signup'>
                  <button type="button" class="w-100 btn btn-lg btn-outline-primary">Sign up for free</button>
                </Link>
              </div>
            </div>
          </div>
          <div class="col-5">
            <div class="card mb-4 rounded-3 shadow-sm">
              <div class="card-header py-3 text-bg-primary border-primary">
                <h4 class="my-0 fw-normal">Pro</h4>
              </div>
              <div class="card-body">
                <h1 class="card-title pricing-card-title">$10<small class="text-muted fw-light">/mo</small></h1>
                <ul class="list-unstyled mt-3 mb-4">
                  <li>5,000+ words</li>
                  <li>100+ grammars</li>
                  <li>Unlimited daily lessons</li>
                  <li>Direct support from founder</li>
                  <li>...and more!</li>
                </ul>
                <Link to='/signup'>
                  <button type="button" class="w-100 btn btn-lg btn-primary signup-btn">Get started</button>
                </Link>
              </div>
            </div>
          </div>
        </div>

        <h2 class="display-6 text-center mb-4">Compare plans</h2>

        <div class="table-responsive">
          <table class="table text-center w-100">
            <thead>
              <tr>
                <th style={{width:'34%'}}></th>
                <th style={{width:'22%'}}>Free</th>
                <th style={{width:'22%'}}>Pro</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th scope="row" class="text-start">Personalized curriculum</th>
                <td><i class="bi bi-check-lg"></i></td>
                <td><i class="bi bi-check-lg"></i></td>
              </tr>
              <tr>
                <th scope="row" class="text-start">Fluency tracking</th>
                <td><i class="bi bi-check-lg"></i></td>
                <td><i class="bi bi-check-lg"></i></td>
              </tr>
              <tr>
                <th scope="row" class="text-start">Streaks</th>
                <td><i class="bi bi-check-lg"></i></td>
                <td><i class="bi bi-check-lg"></i></td>
              </tr>
              <tr>
                <th scope="row" class="text-start">Flashcards - words</th>
                <td><i class="bi bi-check-lg"></i></td>
                <td><i class="bi bi-check-lg"></i></td>
              </tr>
              <tr>
                <th scope="row" class="text-start">Flashcards - grammars</th>
                <td></td>
                <td><i class="bi bi-check-lg"></i></td>
              </tr>
              <tr>
                <th scope="row" class="text-start">Unlimited daily lessons</th>
                <td></td>
                <td><i class="bi bi-check-lg"></i></td>
              </tr>
              <tr>
                <th scope="row" class="text-start">Dictionary</th>
                <td></td>
                <td><i class="bi bi-check-lg"></i></td>
              </tr>
              <tr>
                <th scope="row" class="text-start">Chinese characters</th>
                <td></td>
                <td><i class="bi bi-check-lg"></i></td>
              </tr>
              <tr>
                <th scope="row" class="text-start">Learner community</th>
                <td></td>
                <td><i class="bi bi-check-lg"></i></td>
              </tr>
              <tr>
                <th scope="row" class="text-start">Founder support</th>
                <td></td>
                <td><i class="bi bi-check-lg"></i></td>
              </tr>
            </tbody>
          </table>
        </div>
      </main>
    </div>
  );
}









