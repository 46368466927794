import { useState, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";

import useAuth from "../hooks/auth.hook";
import useTimer from "../hooks/timer.hook";
import useData from "../hooks/data.hook";

export default function Login(props) {
  const navigate = useNavigate();
  const { login, signup, authed } = useAuth();
  const { setUser } = useData('user', {authed});
  const timer = useTimer();
  const formRef = useRef();

  const [state , setState] = useState({
    email: "",
    password: "",
    fname: "",
  });

  const [errorMsg, setErrorMsg] = useState('');
  function showError(msg) {
    setErrorMsg(msg);
    timer.fn(() => {
      setErrorMsg('');
    }, 3000);
  }
  
  const loginMode = !!props.loginMode;
  function handleLogin(e) {
    console.log('login start', state, e);
    e.preventDefault();
    formRef.current.classList.add('was-validated');
    if(!state.email || !state.password) {
      showError('Please specify a valid email and password');
      return;
    }
    login(state.email, state.password).then((user) => {
      if(!!user) {
        setUser(user);
        navigate("/");
      }
      else {
        formRef.current.classList.remove('was-validated');
        showError('Email or password not found, try again');
      }
    });
  }
  function handleSignup(e) {
    console.log('signup start', state);
    e.preventDefault();
    formRef.current.classList.add('was-validated');
    function getClientTimezone() {
      return Intl.DateTimeFormat().resolvedOptions().timeZone;
    }
    if(!state.email || !state.password || !state.fname) {
      showError('Please specify a valid name, email, and password!');
      return;
    }
    signup(state.email, state.password, state.fname, getClientTimezone()).then((user) => {
      if(!!user) {
        setUser(user);
        navigate("/");
      }
      else {
        formRef.current.classList.remove('was-validated');
        showError('Email already exists, please log in instead');
      }
    });
  }
  function handleChange(e) {
    const {id , value} = e.target;
    setState(prevState => ({...prevState, [id]:value}));
  }

  return (
    <>
      { !!errorMsg &&
      <div class="alert alert-danger d-flex align-items-center" role="alert">
        <i class="bi bi-exclamation-triangle-fill me-2"></i>
        <div>{errorMsg}</div>
      </div>
      }
      <main class={`form-signin w-100 m-auto mt-5 ${!loginMode?'signup-mode':''}`} style={{'text-align':'center'}}>
      <form class="needs-validation" noValidate ref={formRef}>
        <h1 class="h3 mb-3 fw-normal">
          {loginMode ? 'Please sign in' : 'Welcome to Easy Chinese!'}
        </h1>

        {!loginMode && 
        <div class="form-floating">
          <input type="text" class="form-control" id="fname" placeholder="Language Learner" required onChange={handleChange} />
          <label for="fname">First name</label>
        </div>
        }
        <div class="form-floating">
          <input type="email" class="form-control" id="email" placeholder="name@example.com" required onChange={handleChange} />
          <label for="email">Email address</label>
        </div>
        <div class="form-floating">
          <input type="password" class="form-control" id="password" placeholder="p4s5w0rd" required onChange={handleChange} />
          <label for="password">Password</label>
        </div>
        <div class="checkbox mb-3" style={{'text-align':'left'}}>
          <label>
            <input type="checkbox" value="remember-me" /> Remember me
          </label>
        </div>
        {loginMode ?
        <>
          <button class="btn btn-action btn-primary btn-lg mb-3 w-100" onClick={handleLogin}>Log in</button>
          <div>Don't have an account? <Link to="/signup">Sign up</Link></div>
        </>
        :
        <>
          <button class="btn btn-action btn-primary btn-lg mb-3 w-100" onClick={handleSignup}>Sign up</button>
          <div>Already have an account? <Link to="/login">Log in</Link></div>
        </>
        }
      </form>
      </main>
    </>
  );
}