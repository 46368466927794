import { useRef, useEffect } from "react";
import { Link } from "react-router-dom";

import useData from "../hooks/data.hook";

import SubHeader from "./subHeader.component";
import Loading from "./loading.component";

import { assert, journeyItemHasContent, renderJourneyItem } from '../common/utility';

export default function Journey({headerRef}) {  
  const { user } = useData('user');
  const { journey } = useData('journey');
  const currentLessonMarker = useRef();

  useEffect(() => {
    const elt = currentLessonMarker.current;
    if(!elt)
      return;
    
    const y = elt.getBoundingClientRect().top + window.pageYOffset - window.innerHeight/2 + 100/2;
    window.scrollTo({top: y, behavior: 'smooth'});
  });

  // return if we haven't loaded data yet
  if(!user || !journey) {
    return (<Loading />);
  }

  // mark current lesson
  function getLessonForSlu(slu) {
    return journey.stages[slu.stage].items[slu.level].items[slu.lesson];
  }
  var currentSlu = {
    stage:user.stage,
    level:user.level,
    lesson:user.lessonIndexActual,
  }
  while(!journeyItemHasContent(getLessonForSlu(currentSlu))) {
    currentSlu.lesson++;
    if(!getLessonForSlu(currentSlu)) {
      currentSlu.level++;
      currentSlu.lesson=0;
      if(!getLessonForSlu(currentSlu)) {
        currentSlu.stage++;
        currentSlu.level=0;
        // otherwise user is done
      }
    }
  }
  const currentLesson = getLessonForSlu(currentSlu);
  assert(currentLesson);
  currentLesson.isCurrentLesson = true;
    
  // render
  //console.log('Journey - render', journey);
  return (<>
    <SubHeader headerRef={headerRef}>
    </SubHeader>
    
    { journey.stages.map(stage=>(<>
      { stage.comingSoon ?
      <div class="alert alert-secondary my-4" role="alert">
        <strong>Stage {stage.index+1} - {stage.name}</strong> (not unlocked yet)
      </div>
      :
      <>
      <h1>Stage {stage.index+1} - {stage.name}</h1>
      { stage.items.map(level=>(<>
        { level.comingSoon ?
        <div class="alert alert-secondary my-4" role="alert">
          <strong>Level {level.index+1} - {level.name}</strong> (not unlocked yet)
        </div>
        : 
        <div class="p-5 panel mt-4">
          <h3 class="mb-3">Level {level.index+1} - {level.name}</h3>
          { level.items.map(lesson=>(
          <>
            { lesson.isCurrentLesson && 
              <div class="row"><div class="col">
                <div class="alert alert-success my-4 fs-3 p-4" role="alert" ref={currentLessonMarker}>
                  <span>
                    <strong>Next lesson <i class="bi bi-arrow-down"/></strong>
                  </span>
                  <Link to='/journey/customize' class="float-end">
                    <button class="btn btn-action btn-outline-secondary">Personalize my curriculum</button>
                  </Link>
                </div>
              </div></div>
            }
            { renderJourneyItem(user, lesson) }
          </>
          )) }
        </div>
        }          
      </>)) }
      </>
      }
    </>)) }

  </>);
}