import { useState, useRef } from "react";
import { Routes, Route, Link, Navigate, useLocation } from "react-router-dom";
import '@popperjs/core/dist/esm/popper.js'
//import "bootstrap/dist/css/bootstrap.min.css";
import "./custom.css"; // custom boostrap css
import "bootstrap-icons/font/bootstrap-icons.css"
import 'bootstrap/dist/js/bootstrap.min.js'

import './App.css';

import Landing from "./components/landing.component";
import About from "./components/about.component";
import Features from "./components/features.component";
import Pricing from "./components/pricing.component";
import Login from "./components/login.component";
import Account from "./components/account.component";
import Map from "./components/map.component";
import Progress from "./components/progress.component";
import Lesson from "./components/lesson.component";
import Feedback from "./components/feedback.component";
import Dictionary from "./components/dictionary.component";
import Journey from "./components/journey.component";
import JourneyCustomize from "./components/journeyCustomize.component";

import useAuth from "./hooks/auth.hook";
import useData from "./hooks/data.hook";
import useVoice from "./hooks/voice.hook";
import useTimer from "./hooks/timer.hook";
import useConfetti from "./hooks/confetti.hook";

import { assert, unicodeSpaces, showExtraTabs, navLink } from './common/utility';
import ENV from "./common/config.env";

if(ENV!=='dev') {
  //console.log = ()=>{};
}

function Header({headerRef}) {
  const { authed } = useAuth();
  const { user, setUser } = useData('user', {page:'header', authed});
  const [ lastQuestionsToday, setLastQuestionsToday ] = useState(null);
  const [ lastLesson, setLastLesson ] = useState(null);
  const questionsTodayRef = useRef();
  const lessonRef = useRef();
  const location = useLocation();
  const voice = useVoice(user);
  const timer = useTimer();
  const { popConfetti, registerConfettiRenderer } = useConfetti();
  const currLocation = location.pathname;
  const renderConfetti = registerConfettiRenderer();
  
  // volume button
  let volumeBtnClass;
  switch(voice.getVolume()) {
    case 'mute':
      volumeBtnClass = 'bi-volume-mute';
      break;
    case 'low':
      volumeBtnClass = 'bi-volume-down';
      break;
    case 'high':
      volumeBtnClass = 'bi-volume-up';
      break;
    default:
      assert(false);
  }
  function onVolumeBtnClick() {
    voice.incVolume(setUser);
  }

  // stats box
  let streakDays, questionsToday, lessonIndex, totalLessons, lessonProgressBarValue = 0;
  const onFlashcardsPage = currLocation==='/practice';
  if(user && user.streakHistory) { // HACK - user={} sometimes. no idea why? so we need an extra check to ensure the user obj is "real"
    lessonIndex = user.lessonIndexActual;
    const todayStreak = user.streakToday;
    questionsToday = todayStreak ? todayStreak.flashcardsComplete+todayStreak.lessonQuestionsComplete : 0;
    assert(!isNaN(questionsToday));
    for(var i=1; i<user.streakHistory.length; ++i) { // ignore current day
      const streak = user.streakHistory.at(-1-i);
      if(!streak?.streak || streak.streak.isEmpty)
        break;
    }
    streakDays = i;
    if(lessonIndex !== lastLesson) {
      setLastLesson(lessonIndex);
      if(lastLesson !== null) {
        popNumber(lessonRef);
      }
    }
    if(questionsToday !== lastQuestionsToday) {
      setLastQuestionsToday(questionsToday);
      if(lastQuestionsToday !== null) {
        var confettiPopType;
        if(onFlashcardsPage && !(questionsToday%20) && questionsToday>0) {
          confettiPopType = 'ok';
        }
        popNumber(questionsTodayRef, confettiPopType);
      }
    }
    totalLessons = user.levelNumLessons; // HACK: at this point it's not really "user" it's more like "base data"
    lessonProgressBarValue = 33+67*(lessonIndex??0)/(totalLessons??1);
  }
  function popNumber(ref, confettiPopType) {
    if(!ref.current) return;
    ref.current.classList.add('pop');
    timer.fn(() => {
      ref.current.classList.remove('pop');
    }, 500);
    if(confettiPopType) {
      popConfetti(confettiPopType);
    }
  }
  function getNumberBadgeColor(num, goodOkLevels) {
    assert(goodOkLevels.length===2);
    const good = goodOkLevels[0];
    const ok = goodOkLevels[1];
    if(num>=good)
      return 'text-bg-success';
    else if(num>=ok)
      return 'text-bg-warning';
    return 'text-bg-medium';
  }

  // render
  //console.log('Header - render', userId, user);
  const classes = 'me-3 text-bg-primary fw-light rounded-3';
  return (
    <div class="row justify-content-center sticky-top" style={{zIndex:2000}} ref={headerRef}><div class="col-md-11">
      <header class="d-flex flex-wrap justify-content-center p-3 text-bg-danger rounded-bottom-3 navbar navbar-expand position-relative">
        <Link to="/" class={`d-flex align-items-center mb-3 mb-md-0 text-white text-decoration-none ${authed?'':'me-auto'}`}>
          <div class={!!authed ? `me-4` : 'me-1'} style={{maskImage:'url(/images/logo/logoMask.svg)', maskRepeat:'no-repeat', maskSize:'cover', width:68, height:40, backgroundColor:'white'}} />
          { !authed &&
            <span class="fs-4 fw-medium">Chinese</span>
          }
        </Link>
        <span class="badge text-bg-danger position-absolute top-100 start-0 translate-middle-y" style={{marginLeft:21.5, marginTop:-4}}>Beta 2</span>

        { currLocation!=='/login' && currLocation!=='/signup' &&
        <>
          <ul class={`nav navbar-nav ${authed?'me-auto':''}`}>
            {!authed ?
            <>
              {navLink({link:'/about', text:'About', isNav:true, currLocation, classes})}
              {navLink({link:'/features', text:'Features', isNav:true, currLocation, classes})}
              {navLink({link:'/pricing', text:'Pricing', isNav:true, currLocation, classes})}
            </>
            :
            <>
              {navLink({link:'/', text:'Progress', isNav:true, currLocation, classes, linkExactMatch:true})}
              {navLink({link:'/lesson', text:'Lesson', isNav:true, currLocation, classes})}
              { showExtraTabs(user) && 
              <>
                {navLink({link:'/practice', text:'Practice', isNav:true, currLocation, classes})}
                {navLink({link:'/journey', text:'Journey', isNav:true, currLocation, classes})}
                {navLink({link:'/dictionary', text:'Dictionary', isNav:true, currLocation, classes})}
              </>
              }
            </>
            }
          </ul>
          <ul class={`nav navbar-nav`}>
            { authed ?
            <>
              <li class="nav-item"><button class={`btn nav-link ${classes.replaceAll('me-3', '')} me-2 px-3`} onClick={onVolumeBtnClick}><i class={`bi ${volumeBtnClass} btn-icon`}></i></button></li>
              <li class="nav-item">
                {navLink({link:'/account', text:<i class={`bi bi-gear btn-icon`} />, isNav:true, currLocation, classes:`${classes} px-3`})}
              </li>
              <li class="nav-item position-relative" style={{width:217}} id="statBox">
                <div class="position-absolute w-100 bg-white rounded-3 p-25" style={{marginTop:-4, boxShadow:'4px 4px 0px 0px rgba(var(--bs-light-rgb),var(--bs-bg-opacity))'}}>
                  <div class="position-absolute top-50 start-50 translate-middle" style={{zIndex:3000}}>
                    { renderConfetti() }
                  </div>
                  <table class="table table-borderless mb-0 w-100 h-100">
                    <tbody>
                      <tr class="mb-0 h-100">
                        <td class="text-dark py-2 px-1 fw-medium pe-2">Lesson</td>
                        <td class="py-2 px-1 w-auto h-100">
                          <div class="progress position-relative w-100 h-100" role="progressbar" ref={lessonRef}>
                            <div class="progress-bar bg-info" style={{width:`${lessonProgressBarValue}%`}}><strong class="fs-6">{(lessonIndex!==undefined)?(lessonIndex+1):''}</strong></div>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <table class="table table-borderless mb-0 w-100">
                    <tbody>
                      <tr class="mb-0">
                        <td class="text-dark py-2 px-1 fw-medium pe-2">Streak days</td>
                        <td class="py-2 px-1"><span class={`badge ${getNumberBadgeColor(streakDays, [7,3])} py-1 fs-6 float-end`} style={{marginTop:-1}}>{streakDays ?? unicodeSpaces(2)}</span></td>
                      </tr>
                      <tr class="mb-0">
                        <td class="text-dark py-2 px-1 fw-medium pe-2 pt-1">Questions today</td>
                        <td class="py-2 px-1 pt-1"><span class={`badge ${getNumberBadgeColor(questionsToday?questionsToday:0, [100,40])} py-1 fs-6 float-end`} style={{marginTop:-1}} ref={questionsTodayRef}>{questionsToday ?? unicodeSpaces(2)}</span></td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </li>
            </>
            :
            <>
              {navLink({link:'/login', text:'Login', isNav:false, currLocation, classes:'btn-outline-light btn-action me-2 ms-4'})}
              {navLink({link:'/signup', text:'Sign up', isNav:false, currLocation, classes:'text-bg-primary btn-action'})}
            </>
            }
          </ul>
        </>
        }
      </header>
    </div></div>
  );
}

function Footer() {
  const { authed } = useAuth();
  const location = useLocation();
  const currLocation = location.pathname;
  
  const classes = 'ms-3 text-muted rounded-3 border px-2 ';
  const activeClasses = `${classes} active`;
  // eslint-disable-next-line
  return (
    <div class="row justify-content-center mt-5"><div class="col-md-11">
      <footer class="d-flex flex-wrap justify-content-between align-items-center p-3 px-4 rounded-top-3 text-bg-light mt-4">
        <p class="col-md-4 mb-0 text-muted">&copy; 2025 Easy Chinese, Inc</p>

        <dev class="col-md-4 d-flex align-items-center justify-content-center mb-3 mb-md-0 me-auto text-decoration-none">
          <Link to="/" class="text-muted">
            <img src="/images/logo/logo-lightBackground.svg" style={{height:40}} />
          </Link>
        </dev>          

        <ul class="nav col-md-4 justify-content-end">
          { !authed ?
          <>
            {navLink({link:'/about', text:'About', isNav:true, classes, activeClasses, currLocation})}
            {navLink({link:'/features', text:'Features', isNav:true, classes, activeClasses, currLocation})}
            {navLink({link:'/pricing', text:'Pricing', isNav:true, classes, activeClasses, currLocation})}
          </>
          :
          <>
            {navLink({link:'https://discord.com/invite/chineselanguage', text:'Community', isNav:true, activeClasses, classes})}
            {navLink({link:'/account', text:'Account', isNav:true, classes, activeClasses, currLocation})}
          </>
          }
        </ul>
      </footer>
    </div></div>
  );
}

function RequireAuth({children}) {
  const { authed } = useAuth();
  return authed === true ? children : <Navigate to="/login" replace />;
}

export default function App() {
  const { authed } = useAuth();
  const headerRef = useRef();

  return (
    <>
      <div>
        <Header headerRef={headerRef} />
        <div className="container mb-5">
          <div class="row justify-content-center">
            <div class="col-11">
              <Routes>
                {/* root */}
                <Route path="/" element={authed ? <Progress headerRef={headerRef}/> : <Landing/>} />

                {/* landing pages */}
                <Route path="/about" element={<About/>} />
                <Route path="/features" element={<Features/>} />
                <Route path="/pricing" element={<Pricing/>} />
                <Route path="/login" element={<Login loginMode={true}/>} />
                <Route path="/signup" element={<Login loginMode={true}/>} />
                <Route path="/admin-signup" element={<Login/>} />

                {/* app */}
                <Route path="/lesson" element={<RequireAuth><Lesson key="lesson" flashcardMode={false} headerRef={headerRef}/></RequireAuth>} />
                <Route path="/practice" element={<RequireAuth><Lesson key="flashcards" flashcardMode={true} headerRef={headerRef}/></RequireAuth>} />
                <Route path="/practice/grammar/:grammarId" element={<RequireAuth><Lesson key="flashcards" flashcardMode={true} headerRef={headerRef}/></RequireAuth>} />

                <Route path="/journey" element={<RequireAuth><Journey headerRef={headerRef}/></RequireAuth>} />
                <Route path="/journey/customize" element={<RequireAuth><JourneyCustomize headerRef={headerRef}/></RequireAuth>} />

                <Route path="/map" element={<RequireAuth><Map headerRef={headerRef}/></RequireAuth>} />
                <Route path="/map/:stageId" element={<RequireAuth><Map headerRef={headerRef}/></RequireAuth>} />
                <Route path="/map/:stageId/:levelId" element={<RequireAuth><Map headerRef={headerRef}/></RequireAuth>} />
                <Route path="/map/:stageId/:levelId/:unitId" element={<RequireAuth><Map headerRef={headerRef}/></RequireAuth>} />

                <Route path="/dictionary" element={<RequireAuth><Dictionary headerRef={headerRef}/></RequireAuth>} />
                <Route path="/dictionary/:modeUrl" element={<RequireAuth><Dictionary headerRef={headerRef}/></RequireAuth>} />
                <Route path="/dictionary/:modeUrl/:id" element={<RequireAuth><Dictionary headerRef={headerRef}/></RequireAuth>} />

                <Route path="/account" element={<RequireAuth><Account key="user" headerRef={headerRef}/></RequireAuth>} />
                <Route path="/account/prefs" element={<RequireAuth><Account key="config" headerRef={headerRef}/></RequireAuth>} />
                <Route path="/account/audio" element={<RequireAuth><Account key="audio" headerRef={headerRef}/></RequireAuth>} />
              </Routes>
            </div>
          </div>
        </div>
        <Footer />
      </div>
      { authed && 
      <Feedback /> 
      }
    </>
  );
}

