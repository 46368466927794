import { useLocalStorage } from "@uidotdev/usehooks";
import 'chart.js/auto';
import { Chart } from 'react-chartjs-2';

import { getDateStr, assert, arrayGetFilled, getFluencyData } from '../common/utility';

// helpers

// options: xUnits, yAxisLabel, yAxisType, dataSetLabels, dataSetColors, unitSelectorName
export function getGraphData(labels, dataSets, options) {
  return {
    labels,
    dataSets,
    options,
  }
}

const documentStyle = getComputedStyle(document.body);
function getRGBForColorName(colorName) {
  return documentStyle.getPropertyValue('--bs-'+colorName+'-rgb');
}
export function graphColor(colorName, isBorder=true) {
  return `rgba(${getRGBForColorName(colorName)}, ${isBorder?1.0:0.5})`;
}
function graphColorInterpolated(colorName1, colorName2, pct) {
  const c1 = getRGBForColorName(colorName1).split(',');
  const c2 = getRGBForColorName(colorName2).split(',');
  const rgb = [];
  for(let i=0; i<3; ++i) {
    rgb.push(c1[i]*(1-pct)+c2[i]*pct);
  }
  return `rgba(${rgb[0]}, ${rgb[1]}, ${rgb[2]}, 1)`;
}

// special graphs

export function getFluencyGraphData(user, streakDataAll, options={}) {
  const { fluencyLabels, fluencyNumbers } = getFluencyData(user, streakDataAll);
  return getGraphData(fluencyLabels, [fluencyNumbers], {
    yAxisLabel:'Estimated vocabulary size',
    unitSelectorName:'vocabFluency',
    colorGradientFill:true,
    ...options,
  }); 
}

// graph component

export function Graph({user, data, className, numDays, titleContent}) {
  const hasUnitSelector = !!data.options.unitSelectorName;
  const unitSelectorName = hasUnitSelector ? data.options.unitSelectorName : '__unusedUnitSelector';
  const [ unitSelector, setUnitSelector ] = useLocalStorage(unitSelectorName, 'days');
  
  const maxLabels = numDays ?? 30;
  
  // init chart data
  const chartData = {};
  chartData.type = data.options.chartType ?? 'line';
  
  const hasDataOverride = !!data.options.chartDataOverride;
  if(!hasDataOverride) {
    // check for empty input
    let labels = data.labels;
    if(!labels.length) {
      labels = [getDateStr(user)];
      data.dataSets = [[0]];
    }
  
    // fill in missing labels & data, in case any are sparse
    let fullLabels = [];
    let i=0;
    while(labels[0]!==fullLabels[0]) {
      const dateStr = getDateStr(user, i);
      fullLabels.unshift(dateStr);
      ++i;
    }
    let fullDataSets = [];
    for(const dataSet of data.dataSets) {
      assert(dataSet.length===labels.length);
      const fullData = fullLabels.map(label=>{
        const index = labels.indexOf(label);
        if(index===-1)
          return 0;
        else
          return dataSet[index];
      });
      fullDataSets.push(fullData);
    }
  
    // group data if unit != 'days'
    let unitPeriod = null;
    const xUnits = hasUnitSelector ? unitSelector : data.options.xUnits ?? 'days';
    switch(xUnits) {
    case 'days':
      unitPeriod = 1;
      break;
    case 'weeks':
      unitPeriod = 7;
      break;
    case 'months':
      unitPeriod = 30;
      break;
    default:
      assert(false, `Invalid unit type passed to getGraphData`);
    }
    assert(unitPeriod!==null);
    if(unitPeriod!==1) {
      const adjustedLabels=[], adjustedDataSets=arrayGetFilled(data.dataSets.length, []);
      let lastTotals;
      function resetLastTotals() {
        lastTotals = arrayGetFilled(data.dataSets.length, 0);
      }
      resetLastTotals();
      function addAdjusted(label) {
        adjustedLabels.unshift(label);
        for(let i=0; i<data.dataSets.length; ++i) {
          adjustedDataSets[i].unshift(lastTotals[i]/(unitPeriod+1));
        }
      }
      unitPeriod -= 1;
      // eslint-disable-next-line
      let j;
      for(let i=fullLabels.length-1, j=0; i>=0; --i, ++j) {
        for(let k=0; k<data.dataSets.length; ++k) {
          lastTotals[k] += fullDataSets[k][i];
        }
        if(j===unitPeriod) {
          addAdjusted(fullLabels[i]);
          j=0;
          resetLastTotals();
        }
      }
      if(lastTotals.some(v=>v>0)) {
        addAdjusted(fullLabels[0]);
      }
      fullLabels = adjustedLabels;
      fullDataSets = adjustedDataSets;
    }
  
    // pad with zeros if we don't have enough data, crop if we have too much
    if(fullLabels.length<10) {
      let firstLabel;
      unitPeriod += 1;
      function labelSubtractDays(label, days) {
        return getDateStr(user, days, new Date(label)); // NOTE: we used to also subtract 5 hrs, seems like a hack / unnecessary?
      }
      if(fullLabels.length===1)
        firstLabel = fullLabels[0];
      else {
        firstLabel = labelSubtractDays(fullLabels[1], unitPeriod);
        if(fullLabels[0]!==firstLabel)
          fullLabels[0] = firstLabel; // handle case where first label is a fractional step away from second
      }
      while(fullLabels.length<10) {
        firstLabel = labelSubtractDays(firstLabel, unitPeriod);
        fullLabels.unshift(firstLabel);
        for(let i=0; i<data.dataSets.length; ++i) {
          fullDataSets[i].unshift(0);
        }
      }
    }
    else if(fullLabels.length>maxLabels) {
      fullLabels = fullLabels.slice(-maxLabels);
      for(let i=0; i<data.dataSets.length; ++i) {
        fullDataSets[i] = fullDataSets[i].slice(-maxLabels);
      }
    }
  
    // plot colors
    const defaultDSColors = [
      'warning',
      'primary',
    ];
    const useCustomColors = !!data.options.dataSetColors;
    if(useCustomColors)
      assert(data.options.dataSetColors.length===data.dataSets.length);
    else
      assert(data.dataSets.length<=defaultDSColors.length);
    const plotColors = [];
    for(let i=0; i<data.dataSets.length; ++i) {
      const colorName = useCustomColors ? data.options.dataSetColors[i] : defaultDSColors[i];
      plotColors.push({
        backgroundColor: graphColor(colorName, false),
        borderColor: graphColor(colorName, true),
      });
    }
  
    // handle gradient fill
    let pointAttrs = {};
    if(data.options.colorGradientFill) {
      assert(data.dataSets.length===1);
      // fill
      function createFillGradient(ctx, chartArea) {
        const { top, bottom } = chartArea;
        const gradient = ctx.createLinearGradient(0, bottom, 0, top);
        gradient.addColorStop(0, graphColor('danger', false));
        gradient.addColorStop(0.3, graphColor('warning', false));
        gradient.addColorStop(1, graphColor('success', false));
        return gradient;
      }
      plotColors[0].backgroundColor = (ctx)=>{
        if(!ctx.chart.chartArea)
          return null;
        return createFillGradient(ctx.chart.ctx, ctx.chart.chartArea);
      };
      // points
      const dataset = fullDataSets[0];
      const min = Math.min(...dataset);
      const max = Math.max(...dataset);
      const range = max-min;
      function getPointColor(value) {
        let topColor, bottomColor, pct;
        value -= min;
        if(range>0)
          value /= range;
        if(value>0.3) {
          topColor = 'warning';
          bottomColor = 'success';
          pct = (value-0.3)/0.7;
        }
        else {
          topColor = 'warning';
          bottomColor = 'danger';
          pct = (0.3-value)/0.3;
        }
        return graphColorInterpolated(topColor, bottomColor, pct);
      }
      const pointColors = dataset.map(val=>getPointColor(val));
      function createLineGradient(ctx, chartArea) {
        const { left, right } = chartArea;
        const gradient = ctx.createLinearGradient(left, 0, right, 0);
        for(let i=0; i<dataset.length; ++i) {
          const pos = i / (dataset.length - 1); // Normalize 0 to 1
          gradient.addColorStop(pos, pointColors[i]);
        }
        return gradient;
      }
      plotColors[0].borderColor = (ctx)=>{
        if(!ctx.chart.chartArea)
          return null;
        return createLineGradient(ctx.chart.ctx, ctx.chart.chartArea);
      };
      pointAttrs = {
        pointBackgroundColor:pointColors,
        pointBorderColor:pointColors,
      };
    }
    
    // prepare chart data
    fullLabels = fullLabels.map(label=>label.slice(5));
    let axisTitle;
    if(data.options.yAxisLabel) {
      axisTitle = {
        display:true,
        text:data.options.yAxisLabel,
      };
    }
    const datasets = [];
    for(let i=0; i<data.dataSets.length; ++i) {
      const fullData = fullDataSets[i];
      datasets.push({
        label:data.options.dataSetLabels?.at(i),
        type:'line',
        data:fullData,
        fill:true,
        backgroundColor:plotColors[i].backgroundColor,
        borderColor:plotColors[i].borderColor,
        tension:0.5,
        pointStyle:fullData.map(val=>val>0),
        ...pointAttrs,
      });
    }
    const showLegend = data.dataSets.length>1;
    
    // save chart data
    chartData.data = {
      labels:fullLabels,
      datasets,
    };
    chartData.options = {
      maintainAspectRatio:false,
      plugins:{
        legend:{
          display:showLegend,
          labels:{padding:20},
        }
      },
      scales:{
        x:{
          grid:{display:false},
          ticks:{display:true}, //, maxTicksLimit:5},
        },
        y:{
          //stacked:true,
          title:axisTitle,
          ticks:{},
          grid:{display:true},
        },
      }
    };
  }
  else {
    chartData.data = data.options.chartDataOverride;
    chartData.options = data.options.chartOptionsOverride;
  }

  // y-axis types
  function addPercent(value) {
    return value+'%';
  }
  function addPercentToContext(context) {
    return addPercent(context.formattedValue);
  }
  if(data.options.yAxisType==='percent') {
    chartData.options.scales[hasDataOverride?'x':'y'].ticks.callback = addPercent;
    chartData.options.plugins.tooltip = {callbacks:{label:addPercentToContext}};
  }
  
  // handle events
  function handleChange(e) {
    const {id} = e.target;
    const lcValue = id.split('-')[1];
    setUnitSelector(lcValue);
  }
  
  // render
  return (
    <div {...{className}}>
      { hasUnitSelector &&
      <div class="row mb-3">
        {!!titleContent && 
          <div class="col-8">
            {titleContent}
          </div>
        }
        <div class={`col-4 ${!titleContent?'offset-8':''}`}>
          <div class="float-right">
            <div class="btn-group float-end" role="group">
              <input type="radio" class="btn-check" name={unitSelectorName} id={`${unitSelectorName}-days`} onChange={handleChange} checked={unitSelector==='days'} />
              <label class="btn btn-outline-info" for={`${unitSelectorName}-days`}>Days</label>

              <input type="radio" class="btn-check" name={unitSelectorName} id={`${unitSelectorName}-weeks`} onChange={handleChange} checked={unitSelector==='weeks'} />
              <label class="btn btn-outline-info" for={`${unitSelectorName}-weeks`}>Weeks</label>

              <input type="radio" class="btn-check" name={unitSelectorName} id={`${unitSelectorName}-months`} onChange={handleChange} checked={unitSelector==='months'} />
              <label class="btn btn-outline-info" for={`${unitSelectorName}-months`}>Months</label>
            </div>
          </div>
        </div>
      </div>
      }
      <div class="panel-inset p-3" style={{height:250,position:'relative'}}>
        <Chart {...chartData} />
      </div>
    </div>
  );
}
