import { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";

import SubHeader from "./subHeader.component";
import Loading from "./loading.component";

import useAuth from "../hooks/auth.hook";
import useData from "../hooks/data.hook";
import useTimer from "../hooks/timer.hook";
import useVoice from "../hooks/voice.hook";

import { navLink, capitalizeFirstLetter, showExtraTabs, saveUserPrefs } from '../common/utility';

export default function Account(props) {
  const { logout } = useAuth();
  const { user, setUser } = useData('user', {page:'account'});
  const timer = useTimer();
  const voice = useVoice();
  
  const [ prefSelects, setPrefSelects ] = useState({});
  const [ audioVoice, setAudioVoice ] = useState(undefined);
  const [ showSaved, setShowSaved ] = useState(false);
  const [ submitting, setSubmitting ] = useState(false);
  
  const navigate = useNavigate();
  const currLocation = useLocation().pathname;
    
  //console.log('Account - render', user);
    
  if(!user) {
    return (<Loading />);
  }

  // voice options
  const voiceOptions = voice.getVoiceOptions('chinese');
  function handleAudioVoiceChange(e) {
    const name = e.target.value;
    setAudioVoice(name);
    voice.playSample(name);
  }

  // init
  const volumeOptions = [
    'mute',
    'low',
    'high',
  ];
  const volumePref = {
    question:'Select volume level for app audio:',
    userAttrName:'audioVolume',
  };
  const lessonPrefOptions = [
    'never',
    'less',
    'default',
    'more',
  ];
  const lessonPrefs = [
    {
      question:'How frequently do you want to learn new grammar?',
      userAttrName:'lessonsPrefGrammars',
      noNever:true,
    },
    {
      question:'How frequently do you want to review vocabulary?',
      userAttrName:'lessonsPrefReviews',
    },
    {
      question:'How frequently do you want pronunciation lessons?',
      userAttrName:'lessonsPrefPronunciations',
    },
    {
      question:'How frequently do you want practical lessons?',
      userAttrName:'lessonsPrefPracticals',
    },
  ];
  const prefs = [...lessonPrefs, volumePref];
  if(!!user) {
    if(!Object.keys(prefSelects).length) {
      for(const pref of lessonPrefs) {
        prefSelects[pref.userAttrName] = lessonPrefOptions.indexOf(user[pref.userAttrName]);
      }
      prefSelects[volumePref.userAttrName] = volumeOptions.indexOf(user[volumePref.userAttrName]);
      setPrefSelects(prefSelects);
    }
    if(audioVoice===undefined) {
      let initialVoice = user.audioVoice;
      if(!voiceOptions.includes(initialVoice))
        initialVoice = voiceOptions[0];
      setAudioVoice(initialVoice);
    }
  }

  // actions
  function handleLogout() {
    logout().then((success) => {
      if(success) {
        navigate("/");
      }
    });
  }
  function onPrefChange(e) {
    let {id, value} = e.target;
    const pref = prefs.find(p=>p.userAttrName===id);
    // eslint-disable-next-line
    if(pref.noNever && value==0)
      value=1;
    setPrefSelects({
      ...prefSelects,
      [id]:value,
    });
  }
  function savePrefs() {
    setSubmitting(true);
    const data = {};
    for(const attr in prefSelects) {
      if(attr.startsWith('lessonsPref'))
        data[attr] = lessonPrefOptions[prefSelects[attr]];
      if(attr.startsWith('audio'))
        data[attr] = volumeOptions[prefSelects[attr]];
    }
    data.audioVoice = audioVoice;
    saveUserPrefs(data, setUser, ()=>{
      setSubmitting(false);
      setShowSaved(true);
      timer.fn(() => {
        setShowSaved(false);
      }, 3000);
    });
  }
  
  // render helpers
  const classes = 'mb-2 text-bg-light text-muted';
  const activeClasses = `${classes.replaceAll('text-bg-light', '').replaceAll('text-muted', '')} text-bg-primary fw-bold`;
  function renderPrefField(pref, prefOptions) {
    const popMiddle = [];
    for(let i=1; i<prefOptions.length-1; ++i) {
      const val = (i/(prefOptions.length-1))*100;
      popMiddle.push({left:`${val}%`, transform:'translateX(-50%)'});
    }
    const prefOptionsPos = [
      {},
      ...popMiddle,
      {right:0},
    ];
    return (
      <div class="row mb-5" key={pref.userAttrName}><div class="col">
        <label for={pref.userAttrName} class="col-form-label mb-2">{pref.question}</label>
        <input type="range" class="form-range" min="0" max={prefOptions.length-1} step="1" id={pref.userAttrName} onChange={onPrefChange} value={prefSelects[pref.userAttrName]} disabled={submitting}/>
        <div class="position-relative">
          { prefOptions.map((option, i)=>
            // eslint-disable-next-line
            <div class={`position-absolute ${prefSelects[pref.userAttrName]==i?'fw-bold':'text-muted'} ${(i===0 && pref.noNever)?'opacity-25':''}`} style={prefOptionsPos[i]}>{capitalizeFirstLetter(option)}</div>
          ) }
        </div>
      </div></div>
    );
  }
  function prefsChanged() {
    if(audioVoice !== user.audioVoice)
      return true;
    
    for(const attr in prefSelects) {
      if(attr.startsWith('lessonsPref') && lessonPrefOptions[prefSelects[attr]] !== user[attr])
        return true;
      if(attr.startsWith('audio') && volumeOptions[prefSelects[attr]] !== user[attr])
        return true;
    }
    return false;
  }
  
  // render
  return (<>
    <SubHeader headerRef={props.headerRef}>
    </SubHeader>
      
    <h1 class="mb-4">Account Settings</h1>
    { !!showSaved &&
    <div class="alert alert-success align-items-center d-flex mb-4" role="alert">
      <i class="bi bi-check-lg me-2"></i>
      <div>Preferences saved</div>
    </div>
    }
    <div class="row py-5 px-4 panel">
      <div class="col-md-3">
        <ul class="nav nav-pills flex-column">
          {navLink({link:'/account', text:'User & password', isNav:true, currLocation, classes, activeClasses, linkExactMatch:true})}
          {navLink({link:'/account/audio', text:'Audio', isNav:true, currLocation, classes, activeClasses})}
          { showExtraTabs(user) && 
            navLink({link:'/account/prefs', text:'Lesson options', isNav:true, currLocation, classes, activeClasses})
          }
        </ul>
      </div>
      <div class="col-md-9">
        { currLocation==='/account' &&
          <>
          <div class="px-4">
            <div class="row mb-3">
              <label for="inputFName" class="col-sm-3 col-form-label">First name</label>
              <div class="col-sm-9">
                <input type="text" class="form-control" id="inputFName" value={user?.fname} disabled />
              </div>
            </div>
            <div class="row mb-3">
              <label for="inputEmail" class="col-sm-3 col-form-label">Email</label>
              <div class="col-sm-9">
                <input type="email" class="form-control" id="inputEmail" value={user?.email} disabled />
              </div>
            </div>
            <div class="row mb-4">
              <label for="inputPassword" class="col-sm-3 col-form-label">Password</label>
              <div class="col-sm-9">
                <input type="password" class="form-control" id="inputPassword" disabled />
              </div>
            </div>
            <div class="row mb-3"><div class="col">
              <button class="btn btn-action btn-danger" onClick={handleLogout}>Log out</button>
            </div></div>
          </div>
          <div class="float-end">
            <button class="btn btn-action btn-primary w-100" disabled>Update</button>
          </div>
          </>
        }
        { currLocation==='/account/prefs' &&
          <>
          <div class="px-4">
            { lessonPrefs.map(pref=>
              renderPrefField(pref, lessonPrefOptions)
            ) }
          </div>
          <div class="float-end">
            <button class="btn btn-action btn-primary" disabled={!prefsChanged() || submitting} onClick={savePrefs}>Update</button>
          </div>
          </>
        }
        { currLocation==='/account/audio' &&
          <>
          <div class="px-4">
            <div class="row mb-3">
              <label for="voiceSelector" class="col-sm-3 col-form-label">Chinese voice</label>
              <div class="col-sm-9">
                <select class="form-select" id="voiceSelector" disabled={submitting} onChange={handleAudioVoiceChange}>
                  { voiceOptions.map(option=>
                    <option value={option} selected={option===audioVoice}>{capitalizeFirstLetter(option)}</option>
                  )}
                </select>
              </div>
            </div>
            { 
              renderPrefField(volumePref, volumeOptions)
            }
          </div>
          <div class="float-end">
            <button class="btn btn-action btn-primary" disabled={!prefsChanged() || submitting} onClick={savePrefs}>Update</button>
          </div>
          </>
        }
      </div>
    </div>
  </>);
}