// arrays
function arrayGetFilled(length, withThis) {
  return (new Array(length)).fill(withThis);
}
function arrayDedupe(arr, equalsFunc) {
  const out = [];
  if(!equalsFunc)
    equalsFunc = (a,b)=>a==b;
  arr.forEach(elt=>{
    if(out.findIndex(outElt=>equalsFunc(outElt, elt))===-1)
      out.push(elt);
  });
  return out;
}
function arrayDedupeInplace(arr, equalsFunc) {
  if(!equalsFunc)
    equalsFunc = (a,b)=>a==b;
  for(var i=0; i<arr.length; ++i) {
    var found = false;
    for(var j=0; j<i && !found; ++j) {
      found = equalsFunc(arr[i], arr[j]);
    }
    if(found) {
      arr.splice(i, 1);
      --i;
    }
  }
  return arr; // for chaining
}
// modifies the array in place
function arrayShuffle(array) {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
  return array; // for chaining
}
// modifies the array in place
function arrayRemoveValue(arr, val, absentOk=false) {
  const index = arr.indexOf(val);
  if(index!==-1)
    arr.splice(index, 1);
  else    
    assert(absentOk);
  return arr; // for chaining
}
function arrayGetRandomItem(array, func = null) {
  if(func) {
    array = array.filter(func);
  }
  return array[Math.floor(array.length*Math.random())];
}

// objects
function objectEquals(x, y) {
  return (x && y && typeof x === 'object' && typeof y === 'object') ?
    (Object.keys(x).length === Object.keys(y).length) &&
      Object.keys(x).reduce(function(isEqual, key) {
        return isEqual && objectEquals(x[key], y[key]);
      }, true) : (x === y);
}
function traverseObject(elt, func) {
  if(!!elt) {
    if(typeof elt === 'object' && !Array.isArray(elt)) {
      if(elt.__visited)
        return;
      elt.__visited = true;
      for(const key in elt) {
        const subElt = func(elt, key);
        if(subElt) {
          traverseObject(subElt, func);
        }
      }
      delete elt.__visited;
    }
    else if(Array.isArray(elt)) {
      for(const subElt of elt) {
        traverseObject(subElt, func);
      }
    }
  }
  return elt;
}
function objectDeleteData(obj, equalsAny, startsWith=null) { // deletes in place
  return traverseObject(obj, (elt, key)=>{
    var shouldDelete = false;
    if(!!startsWith && key.charAt(0)==startsWith)
      shouldDelete = true;
    if(equalsAny?.includes(key))
      shouldDelete = true;
    var returnElt;
    if(shouldDelete) {
      delete elt[key];
      returnElt = null;
    }
    else {
      returnElt = elt[key];
    }
    return returnElt;
  });
}

// journey
function journeyItemHasContent(lesson) {
  return !!lesson.themes?.length || !!lesson.words?.length || !!lesson.grammars?.length;
}

// fluency
function getDateStr(user, daysAgo=0, date=new Date()) {
  date = new Date(date); // copy
  date.setDate(date.getDate()-daysAgo);

  const dateParts = (new Intl.DateTimeFormat("en-US", {
    timeZone: user.timezone,
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
  })).formatToParts(date).map(p=>p.value);
  
  return [dateParts[4], dateParts[0], dateParts[2]].join('-');
}
function getFluencyData(user, streakData) {
  const firstDay = streakData[0].day;
  const dayData = {};
  for(const streak of streakData) {
    dayData[streak.day] = streak;
  }
  const fullLabels = [];
  let i=0;
  while(firstDay!==fullLabels[0]) { // starting from today, go backwards creating labels until we hit the earliest day in our label set
    const dateStr = getDateStr(user, i);
    fullLabels.unshift(dateStr);
    ++i;
  }
  const fullData = [];
  const wordRecords = {};
  const fluencyInc = 0.34;
  for(i=0; i<fullLabels.length; ++i) {
    const day = fullLabels[i];
    const uniqueWords = dayData[day]?.uniqueWords;
    const wrongWords = dayData[day]?.wrongWords;
    if(uniqueWords) {
      for(const wordId of uniqueWords) { // create records for new words
        if(!(wordId in wordRecords)) {
          wordRecords[wordId] = {
            fluency:0,
            tier:-1, // gets inc'd immediately
            rwStart:i, // reinforcement window
            rwEnd:i,
          };
        }
      }
    }
    let fluency = 0;
    for(const wordId in wordRecords) { // for all records, 1) see if we're past RW, if so, dec the tier 2) if we hit today, inc the fluency. if we hit during the RW, inc the tier 3) if we didn't hit, dec fluency by an exponential factor based on tier 4) add up fluencies for every word we have ever seen to get total fluency
      const record = wordRecords[wordId];
      let resetWindow = false;
      if(i>record.rwEnd) {
        record.tier = Math.max(0, record.tier-1);
        resetWindow = true;
      }
      const hitToday = uniqueWords?.includes(wordId);
      if(hitToday) {
        const wrongToday = wrongWords?.includes(wordId);
        const correct = !wrongToday;
        if(correct) {
          record.fluency = Math.min(1, record.fluency+fluencyInc);
          if(i>=record.rwStart && i<=record.rwEnd)
            record.tier++;
          resetWindow = true;
        }
        else {
          record.fluency /= 2;
        }
      }
      else {
        record.fluency *= 1-Math.pow(0.5, record.tier+1);
      }
      if(resetWindow) {
        record.rwStart = i+((2<<record.tier)>>1); // need this since we can't <<-1
        record.rwEnd = i+(2<<record.tier);
      }
      fluency += record.fluency;
    }
    if(fluency<0.5)
      fluency = 0;
    fullData.push(fluency);
  }
  return {
    fluencyLabels:fullLabels,
    fluencyNumbers:fullData,
  };
}
function getFluencyTrend(fluencyNumbers) {
  let totalDiff = 0, max=-Infinity;
  for(let i=0; i<fluencyNumbers.length-1; ++i) {
    totalDiff += fluencyNumbers[i+1]-fluencyNumbers[i];
    if(fluencyNumbers[i]>max)
      max = fluencyNumbers[i];
  }
  const window = max*0.2;
  let direction = 'flat';
  if(totalDiff>window) {
    direction = 'up';
  }
  else if(totalDiff<-window) {
    direction = 'down';
  }
  return direction;
}

module.exports = {
  arrayGetFilled, arrayDedupe, arrayDedupeInplace, arrayShuffle, arrayRemoveValue, arrayGetRandomItem,
  getDateStr,
  objectEquals, traverseObject, objectDeleteData,
  journeyItemHasContent,
  getFluencyData, getFluencyTrend,
};