import { useEffect } from "react";
import { Link } from "react-router-dom";

export default function Landing() {
  useEffect(() => {
    window.scrollTo({top: 0, behavior: 'instant'});
  }); // run ever render

  return (
    <div class="mt-5">
      {/* hero w/ login CTA */}
      <div class="p-5 py-4 mb-4 panel">
        <div class="container-fluid py-5">
          <div class="row">
            <div class="col-md-8">
              <h1 class="display-5 fw-bold">Learn to speak Chinese in <strong>30 days</strong></h1>
              <p class="fs-4">Want to learn Chinese but afraid of characters or tones? Easy Chinese is a new way to learn, bypassing reading and writing.</p>
              <Link to="/signup">
                <button className="btn btn-action btn-primary mt-3 me-2 btn-lg">Start now <i class="bi bi-chevron-right ms-1 fs-6"></i></button>
              </Link>
            </div>
            <div class="col-md-4 text-center">
              <i class="bi bi-globe-asia-australia text-success" style={{'font-size':'150px'}}></i>
            </div>
          </div>
        </div>
      </div>

      {/* features */}
      <div class="container px-4 py-5">
        <div class="row border-bottom">
          <div class="col-md-12">
            <h2 class="pb-2">Learn</h2>
          </div>
        </div>
        <div class="row g-5 py-5">
          <div class="col-md-6 align-items-start d-flex flex-column gap-2" style={{margin:'auto 0'}}>
            <h3 class="fw-bold">Advanced features to help you learn quickly</h3>
            <p class="text-muted">We studied other apps, books, and classroom learning methods and removed everything that slows down the learner</p>
            <Link to="/signup" class="btn btn-outline-primary btn-lg">Try it</Link>
          </div>
          <div class="col-md-6 row g-4">
            <div class="col-md-6 d-flex flex-column gap-2">
              <div class="feature-icon-small d-inline-flex align-items-center justify-content-center text-bg-warning bg-gradient fs-4 rounded-3">
                <i class="bi bi-collection"></i>
              </div>
              <h4 class="fw-semibold mb-0">Speaking first</h4>
              <p class="text-muted">Skip reading and writing, start learning to speak and understand Chinese from day 1</p>
            </div>
            <div class="col-md-6 d-flex flex-column gap-2">
              <div class="feature-icon-small d-inline-flex align-items-center justify-content-center text-bg-warning bg-gradient fs-4 rounded-3">
                <i class="bi bi-gear-fill"></i>
              </div>
              <h4 class="fw-semibold mb-0">Fluency tracking</h4>
              <p class="text-muted">Watch your fluency grow over time with our 3 key fluency metrics</p>
            </div>
            <div class="col-md-6 d-flex flex-column gap-2">
              <div class="feature-icon-small d-inline-flex align-items-center justify-content-center text-bg-warning bg-gradient fs-4 rounded-3">
                <i class="bi bi-speedometer"></i>
              </div>
              <h4 class="fw-semibold mb-0">Vocabulary</h4>
              <p class="text-muted">Enjoy our hand-built library of the most useful vocabulary words and grammar structures</p>
            </div>
            <div class="col-md-6 d-flex flex-column gap-2">
              <div class="feature-icon-small d-inline-flex align-items-center justify-content-center text-bg-warning bg-gradient fs-4 rounded-3">
                <i class="bi bi-table"></i>
              </div>
              <h4 class="fw-semibold mb-0">Keyboard shortcuts</h4>
              <p class="text-muted">Do everything, including "speaking", "listening", and advancing questions, from your keyboard</p>
            </div>
          </div>
        </div>
      </div>        

      <div class="container px-4 py-5">
        <div class="row border-bottom">
          <div class="col-md-12">
            <h2 class="pb-2 float-end">Practice</h2>
          </div>
        </div>
        <div class="row g-5 py-5">
          <div class="col-md-6 row g-4">
            <div class="col-md-6 d-flex flex-column gap-2">
              <div class="feature-icon-small d-inline-flex align-items-center justify-content-center text-bg-info bg-gradient fs-4 rounded-3">
                <i class="bi bi-collection"></i>
              </div>
              <h4 class="fw-semibold mb-0">Personalized</h4>
              <p class="text-muted">Automatically generated flashcards that laser-target your weakest words and grammars</p>
            </div>
            <div class="col-md-6 d-flex flex-column gap-2">
              <div class="feature-icon-small d-inline-flex align-items-center justify-content-center text-bg-info bg-gradient fs-4 rounded-3">
                <i class="bi bi-gear-fill"></i>
              </div>
              <h4 class="fw-semibold mb-0">Unlimited</h4>
              <p class="text-muted">Practice as much as you want with unlimited daily lessons and flashcards</p>
            </div>
            <div class="col-md-6 d-flex flex-column gap-2">
              <div class="feature-icon-small d-inline-flex align-items-center justify-content-center text-bg-info bg-gradient fs-4 rounded-3">
                <i class="bi bi-speedometer"></i>
              </div>
              <h4 class="fw-semibold mb-0">Mastery data</h4>
              <p class="text-muted">View fine-grained data for each word like when you learned it and how many times you missed it</p>
            </div>
            <div class="col-md-6 d-flex flex-column gap-2">
              <div class="feature-icon-small d-inline-flex align-items-center justify-content-center text-bg-info bg-gradient fs-4 rounded-3">
                <i class="bi bi-table"></i>
              </div>
              <h4 class="fw-semibold mb-0">Spaced repitition</h4>
              <p class="text-muted">Words you have mastered are quizzed with an exponentially backed-off frequency</p>
            </div>
          </div>
          <div class="col-md-6 align-items-start d-flex flex-column gap-2" style={{margin:'auto 0'}}>
            <h3 class="fw-bold">Keep your skills sharp</h3>
            <p class="text-muted">Our adaptive practice sets fit to your exact needs so you can develop where you need most</p>
            <Link to="/signup" class="btn btn-outline-primary btn-lg">Try it</Link>
          </div>
        </div>
      </div>
      
      <div class="container px-4 py-5">
        <div class="row border-bottom">
          <div class="col-md-12">
            <h2 class="pb-2">Grow</h2>
          </div>
        </div>
        <div class="row g-5 py-5">
          <div class="col-md-6 align-items-start d-flex flex-column gap-2" style={{margin:'auto 0'}}>
            <h3 class="fw-bold">Designed to keep you motivated and learning</h3>
            <p class="text-muted">Completely new approach to language learning motivation hacking based on progressive disclosure</p>
            <Link to="/signup" class="btn btn-outline-primary btn-lg">Try it</Link>
          </div>
          <div class="col-md-6 row g-4">
            <div class="col-md-6 d-flex flex-column gap-2">
              <div class="feature-icon-small d-inline-flex align-items-center justify-content-center text-bg-danger bg-gradient fs-4 rounded-3">
                <i class="bi bi-collection"></i>
              </div>
              <h4 class="fw-semibold mb-0">No characters</h4>
              <p class="text-muted">Learn a form of Chinese that is represented by the A-Z Roman alphabet</p>
            </div>
            <div class="col-md-6 d-flex flex-column gap-2">
              <div class="feature-icon-small d-inline-flex align-items-center justify-content-center text-bg-danger bg-gradient fs-4 rounded-3">
                <i class="bi bi-gear-fill"></i>
              </div>
              <h4 class="fw-semibold mb-0">Deferred tones</h4>
              <p class="text-muted">Learn Chinese without tones first then layer on the tones later</p>
            </div>
            <div class="col-md-6 d-flex flex-column gap-2">
              <div class="feature-icon-small d-inline-flex align-items-center justify-content-center text-bg-danger bg-gradient fs-4 rounded-3">
                <i class="bi bi-speedometer"></i>
              </div>
              <h4 class="fw-semibold mb-0">Streaks</h4>
              <p class="text-muted">Track your daily, weekly, and monthly progress with graphs of your activity</p>
            </div>
            <div class="col-md-6 d-flex flex-column gap-2">
              <div class="feature-icon-small d-inline-flex align-items-center justify-content-center text-bg-danger bg-gradient fs-4 rounded-3">
                <i class="bi bi-table"></i>
              </div>
              <h4 class="fw-semibold mb-0">Learner community</h4>
              <p class="text-muted">Meet and converse with other friendly Chinese learners, get 1:1 guidance when you&apos;re stuck</p>
            </div>
          </div>
        </div>
      </div>        
    
    </div>
  );
}