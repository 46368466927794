import { Link, useParams } from "react-router-dom";

import useData from "../hooks/data.hook";

import LessonCard from "./lessonCard.component";
import SubHeader from "./subHeader.component";
import Loading from "./loading.component";

import { getMapPath, getSLUTypeName, assert } from '../common/utility';

export default function Map({headerRef}) {  
  const { user } = useData('user');
  const { map } = useData('map');  
  var { stageId, levelId } = useParams();

  console.log('Map - render', map);

  if(!user || !map) {
    return (<Loading />);
  }
  
  // get SLU, breadcrumbs, prev/next links
  var lastSLU, childType = 'stage';
  const bcs = [], indicies = [];
  var isCurrentSLU = true;
  const prevNextLinks = [];
  function addPNL(title, path) {
    prevNextLinks.push({ title, path });
  }
  function addBC(title, path) {
    bcs.push({ title, path });
  }
  function parseId(idStr) {
    return parseInt(idStr.split('-')[1])-1;
  }
  addBC('All Stages', getMapPath())
  if(stageId) {
    stageId = parseId(stageId);
    addBC(map.stages[stageId].name, getMapPath(stageId));
    indicies.push(stageId);
    lastSLU = map.stages[stageId];
    isCurrentSLU = isCurrentSLU && (stageId===user.stage);
    childType = 'level';
    if(levelId) {
      levelId = parseId(levelId);
      addBC(map.stages[stageId].levels[levelId].name, getMapPath(stageId, levelId));
      indicies.push(levelId);
      lastSLU = lastSLU.levels[levelId];
      isCurrentSLU = isCurrentSLU && (levelId===user.level);
    }

    var sluTypeName = getSLUTypeName(lastSLU.type), indiciesCopy;
    if(lastSLU.id>0) {
      indiciesCopy = [...indicies];
      indiciesCopy[indiciesCopy.length-1]--;
      addPNL(`Previous ${sluTypeName}`, getMapPath(...indiciesCopy));
    }
    if(lastSLU.id<(lastSLU.numSiblings-1)) {
      indiciesCopy = [...indicies];
      indiciesCopy[indiciesCopy.length-1]++;
      addPNL(`Next ${sluTypeName}`, getMapPath(...indiciesCopy));
    }
  }
  console.log('lastSLU', lastSLU, bcs, indicies, prevNextLinks);
  
  // get child cards
  var childSLUs = [], currentChild = null;
  var childThemes = [];
  if(!lastSLU) {
    childSLUs = map.stages;
    currentChild = user.stage;
  }
  else {
    switch(lastSLU.type) {
    case 'stage':
      childSLUs = lastSLU.levels;
      currentChild = user.level;
      break;
    case 'level':
      childThemes = lastSLU.exampleThemes.slice(0,8);
      break;
    default:
      assert(false);
    }
  }
  console.log(childSLUs);
  
  return (<>
    <SubHeader headerRef={headerRef} backgroundColor="white">
      <div class={`row ${!lastSLU ? 'invisible' : ''}`}>
        <nav style={{'--bs-breadcrumb-divider':`'>'`}} class="col-md-8">
          <ol class="breadcrumb">
            { bcs.map((bc, index)=>(
              (index===(bcs.length-1)) ?
              <li class={`breadcrumb-item active`} key={index}>
                {bc.title}
              </li>
              :
              <li class={`breadcrumb-item`} key={index}>
                <Link to={bc.path}>{bc.title}</Link>
              </li>
            ))}
          </ol>
        </nav>
        <div class="col-md-4">
          <span class="float-end">
            { prevNextLinks.map((pnl, index)=>
            <>
              <Link to={pnl.path}>{pnl.title}</Link>
              { (!index && prevNextLinks.length===2) &&
              <span> / </span>
              }
            </>
            )}
          </span>
        </div>
      </div>
    </SubHeader>
    <div class="p-5 panel mt-4">
      { !!lastSLU &&
      <LessonCard type={lastSLU.type} slu={lastSLU} showSLUType={true} showSLUCurrent={isCurrentSLU} usedWordData={map.usedWordData} user={user} class="mb-4" />
      }
      { !!lastSLU && isCurrentSLU &&
      <div class="panel-inset p-3 px-4 mt-4 mx-2 row">
        <div class="col">
          <div class="row">
            <div class="col fs-4 text-muted">
              <span>Progress</span>
              <span class="badge bg-success float-end">{Math.ceil(100/lastSLU.totalWords*(lastSLU.learnedWords))}%</span>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <div className="progress mt-3 mb-2" role="progressbar">
                <div className="progress-bar" style={{width:`${100/lastSLU.totalWords*(lastSLU.learnedWords)}%`}}></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      }
      { !!childSLUs.length &&
      <div class={`row ${!!lastSLU ? 'mt-5' : ''}`}>
        <h3 class="mb-3">{getSLUTypeName(childType)}s</h3>
        { childSLUs.map((childSLU, index)=>
        <div className="card mb-3" key={index}>
          <div className="card-body p-4">
            <div class="row">
              <div class="col-md-9">
                <h4>
                  {getSLUTypeName(childSLU.type)+' '+(childSLU.id+1)+' - '}
                  {childSLU.name} 
                  { isCurrentSLU && (index===currentChild) &&
                  <span className={`badge text-bg-success ms-3`}>Current {getSLUTypeName(childSLU.type)}</span>
                  }
                </h4>
                <p class="text-muted mb-0 lh-lg">{childSLU.descShort}</p>
              </div>
              <div class="col-md-3">
                <Link to={getMapPath(...indicies, index)}><button class="btn btn-outline-secondary float-end">View</button></Link>
              </div>
            </div>
          </div>
        </div>          
        )}
      </div>
      }
      { !!childThemes.length &&
      <div class="row mt-5">
        <h3 class="mb-3">Sample Topics</h3>
        { childThemes.map((childTheme, index)=>
        <div className="col-md-3 mb-3 p-3" key={index}>
          <div class="card">
            <div className="card-body text-center">
              <div style={{'font-size':'50px'}}>{childTheme.emoji ? childTheme.emoji : '🤷‍♂️'}</div>
              <h4>{childTheme.name}</h4>
            </div>
          </div>
        </div>          
        )}
      </div>
      }
    </div>
  </>);
}