import http from "./http-common";

function getAPIPath(path) {
  return `/user`+(path?`/${path}`:'');
}
async function userAPIGet(path) {
  var out = await http().get(getAPIPath(path));
  out = out.data;
  console.log(`userAPIGet ${getAPIPath(path)}`, path ? out?.result : out);
  return out;
}
async function userAPIPut(path, data) {
  var out = await http().put(`/user/${path}`, data);
  out = out.data;
  return out;
}

class UserDataService {
  async getUser() {
    return await userAPIGet();
  }
  async getFlashcards({wordOnly, practiceGrammarId}) {
    return await userAPIGet(`flashcards/${!!practiceGrammarId?('grammar/'+practiceGrammarId):('practice'+(wordOnly?'/wordOnly':''))}`);
  }
  async getLesson() {
    return await userAPIGet(`lesson`);
  }
  async finishLesson(data) {
    return await userAPIPut(`finishLesson`, data);
  }
  async getProgress() {
    return await userAPIGet(`progress`);
  }
  async getMap() {
    return await userAPIGet(`map`);
  }
  async getDictionary() {
    return await userAPIGet(`dictionary`);
  }
  async getJourney() {
    return await userAPIGet(`journey`);
  }
  async updatePrefs(data) {
    return await userAPIPut(`updatePrefs`, data);
  }
  async updateJourney(data) {
    return await userAPIPut(`updateJourney`, data);
  }
  async updateJourneyResolve(data) {
    return await userAPIPut(`updateJourneyResolve`, data);
  }
}

const userDataService = new UserDataService();
export default userDataService;