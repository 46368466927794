import { useState, useEffect, useRef } from "react";
import { Toast } from 'bootstrap';
import { useLocalStorage } from "@uidotdev/usehooks";
import { Link, useNavigate, useParams } from "react-router-dom";

import { getSLUTypeName, formatEnglish,
  getDictionaryPath, showExtraTabs, disabledLinkStyles, 
  arrayIntersperse, arrayDedupeInplace, assert,
  getStreakDay,
  getFluencyData, getFluencyTrend,
  getGrammarVisual, renderJourneyItem, renderFluencySummary,
  objectEquals,
} from '../common/utility';
import UserDataService from "../services/user.service";

import ChinesePhrase from "./chineseWord.component";
import LessonCard from "./lessonCard.component";
import SubHeader from "./subHeader.component";
import Loading from "./loading.component";
import WordCard from "./wordCard.component";
import { getGraphData, graphColor, Graph } from './graph.component';

import useData from "../hooks/data.hook";
import useVoice from "../hooks/voice.hook";
import useConfetti from "../hooks/confetti.hook";
import useForceUpdate from "../hooks/forceUpdate.hook";

const QUESTION_INDEX_NONE = 'none';
const QUESTION_INDEX_SUMMARY = 'summary';
const QUESTION_INDEX_SUGGESTION = 'suggestion';
const QUESTION_INDEX_INTRO = 'intro';
const NULL_ANSWER = '';

// HACK: or is it? need this data not to be erased when user switches pages, can't put it in state, don't want it in localstorage, ...
function resetProgress(page) {
  allProgress[page] = {
    questionIndex:QUESTION_INDEX_NONE,
    answers:{},
  };
}
let allProgress = {};
resetProgress('lesson');
resetProgress('flashcards');
let loadingQuestions = false; // only used to avoid showing answer card while new questions are loading
let needFocusChange = null;
let needToastShow = false;
let lastFlashcardsOptions;

export default function Lesson(props) {
  const { grammarId:practiceGrammarId } = useParams();

  const [ grammarsOn, setGrammarsOn ] = useLocalStorage("grammarsOn", false);
  const { user, setUser } = useData('user');
  const { lesson, loadLesson, forceReloadLesson } = useData('lesson', { noInitialLoad:true });
  const { flashcards, loadFlashcards, forceReloadFlashcards } = useData('flashcards', { wordOnly:!grammarsOn, practiceGrammarId, noInitialLoad:true });

  const [ answer, setAnswer ] = useState(NULL_ANSWER);
  const [ nextEnabled, setNextEnabled ] = useState(true);
  const [ showHint, setShowHint ] = useState(false);
  const [ revealLevel, setRevealLevel ] = useState(0);
  const [ dropItems, setDropItems ] = useState({});
  const [ multiChoice, setMultiChoice ] = useState(-1);
  const toastsRef = useRef([]);
  const nextBtnRef = useRef();
  const inputBoxRef = useRef();
  const [toasts, setToasts] = useState([]);
  const voice = useVoice(user);
  const { popConfetti } = useConfetti();
  const forceUpdate = useForceUpdate();
  const navigate = useNavigate();
    
  // handle focus
  useEffect(() => {
    if(!!needFocusChange) {
      switch(needFocusChange) {
      case 'input':
        if(!!inputBoxRef.current) {
          inputBoxRef.current.focus();
          needFocusChange = null;
        }
        break;
      case 'next':
        if(!!nextBtnRef.current) {
          nextBtnRef.current.focus();
          needFocusChange = null;
        }
        break;
      default:
        assert(false);
      }
    }
    if(needToastShow) { // welcome to the toast show
      const bsToast = Toast.getOrCreateInstance(toastsRef.current[toasts.length-1]);
      assert(bsToast);
      bsToast.show();
      needToastShow = false;
    }
  });

  // get questions data
  const flashcardMode = !!props.flashcardMode;
  let page, questionSet;
  if(flashcardMode) {
    page = 'flashcards';    
    if(flashcards) {
      const newFlashcardsOptions = {
        wordOnly:!grammarsOn,
        grammarId:practiceGrammarId,
      };
      if(objectEquals(lastFlashcardsOptions, newFlashcardsOptions)) {
        questionSet = flashcards;
      }
      else {
        getNewQuestions();
      }
      lastFlashcardsOptions = newFlashcardsOptions;
    }
  }
  else {
    page = 'lesson';
    questionSet = lesson;
    if(lesson) {
      for(const question of lesson.questions) { // add word data to usedWordData. we don't pass it over the wire because it's a waste to have it in the question & in usedWordData
        if(question.type==='word' && !(question.data.wordId in lesson.usedWordData))
          lesson.usedWordData[question.data.wordId] = question.data;
      }
    }
  }
  useEffect(()=>{
    if(!questionSet) {
      function loadCb() {
        forceUpdate();
      }
      if(flashcardMode) {
        loadFlashcards(loadCb);
      }
      else {
        loadLesson(loadCb);
      }
    }
  // eslint-disable-next-line
  }, []); // run once when component is created
  let answers;
  function resetAnswers() {
    answers = allProgress[page].answers;
  }
  resetAnswers();
  const questionIndex = allProgress[page].questionIndex;
  let question;

  // if still loading first questions set via useData calls
  function loadingScreen() {
    return (<Loading />);
  }
  if(!user || !questionSet) {
    return loadingScreen();
  }
  // flashcards - if we tried loading on the first lesson, we don't have any yet
  else if(!questionSet.questions.length) {
    getNewQuestions();
    return (<>No flashcards available yet! Try completing your first lesson</>);
  }
  // if we are on the summary
  else if(questionIndex === QUESTION_INDEX_SUMMARY || questionIndex === QUESTION_INDEX_INTRO || questionIndex === QUESTION_INDEX_SUGGESTION) {
    question = {};
  }
  // if we have loaded the first question set but haven't set the question
  else if(questionIndex === QUESTION_INDEX_NONE) {
    finishQuestionsLoad(questionSet);
    return loadingScreen();
  }
  // we are on a regular question
  else {
    question = questionSet.questions[questionIndex];
  }
  let quizType = null;
  let quizSubType = null;
  if(question?.isQuiz) {
    assert(question.type.includes('-'));
    quizType = question.type.split('-')[0];
    quizSubType = question.type.split('-')[1];
  }

  // question & answer funcs
  function beginLesson() {
    if(questionSet.type==='review')
      setQuestionIndex(QUESTION_INDEX_INTRO);
    else
      setCurrentQuestion(0);
  }
  function finishQuestionsLoad(newQS) {
    //console.log('Lesson - loaded questions', newQS);
    questionSet = newQS;
    if(questionSet.suggestion) {
      setQuestionIndex(QUESTION_INDEX_SUGGESTION);
    }
    else {
      beginLesson();
    }
  }
  function getNewQuestions(optionsOverride = null) {
    loadingQuestions = true; // set this in case it wasn't already set
    
    function reloadDone(newQS) {
      resetProgress(page);
      resetAnswers();
      console.log('reset progress & answers');
      loadingQuestions = false;
      finishQuestionsLoad(newQS);
    }
    if(flashcardMode) {
      forceReloadFlashcards(reloadDone, optionsOverride);
    }
    else {
      forceReloadLesson(reloadDone, optionsOverride);
    }
  }
  function setQuestionIndex(newIndex) {
    allProgress[page].questionIndex = newIndex;
    forceUpdate();
  }
  function setCurrentQuestion(index) {
    // set index
    setQuestionIndex(index);
    // get info
    const nextQuestion = questionSet.questions[index];
    const alreadyAnswered = index in answers;
    // set or reset answer data
    //console.log('set question', index, answers, alreadyAnswered, questionSet, nextQuestion);
    if(nextQuestion.isQuiz) {
      const nextQuizType = nextQuestion.type.split('-')[0];
      switch(nextQuizType) {
      case 'typeIn':
        let answerText = '';
        if(alreadyAnswered)
          answerText = answers[index].text;
        setAnswer(answerText);
        break;
      case 'match':
        let newDropItems = {};
        if(alreadyAnswered)
          newDropItems = answers[index].dropItems;
        setDropItems(newDropItems);
        break;
      case 'multi':
        let choice = -1;
        if(alreadyAnswered)
          choice = answers[index].choice;
        setMultiChoice(choice);
        break;
      default:
        assert(false);
      }
    }
    // set UI
    setNextEnabled(!nextQuestion.isQuiz || alreadyAnswered);
    if(nextQuestion.isQuiz)
      needFocusChange = 'input';
    else
      needFocusChange = 'next';
    // optional voice audio
    if(!alreadyAnswered) {
      let voiceText, lang = 'chinese';
      switch(nextQuestion.type) {
        case 'word':
          voiceText = nextQuestion.data.characters;
          break;
        case 'typeIn-chineseToEnglish':
          voiceText = nextQuestion.chinese.words.map(w=>w.characters).join('');
          break;
        case 'typeIn-englishToChinese':
          voiceText = formatEnglish(nextQuestion.english.text, user);
          lang = 'english';
          break;
        case 'multi-wordToDef':
          voiceText = lesson.usedWordData[nextQuestion.wordId].characters;
          break;
        case 'multi-defToWord':
          voiceText = lesson.usedWordData[nextQuestion.wordId].characters;
          lang = 'english';
          break;
        case 'multi-soundToName':
          voiceText = nextQuestion.syllableCharacter;
          break;
        default:
          // no action item, other question types aren't spoken
      }
      if(voiceText)
        voice.speak(voiceText, lang);
    }
  }
  function onChangeAnswer(e) {
    const answer = e.target.value;
    setAnswer(answer);
    setNextEnabled(!!answer);
  }
  function onKeyDown(e) {
    if(questionSet.questions[questionIndex]?.isQuiz) {
      if(quizType==='multi') {
        console.log('multi type', e);
      }
      if(e.key === 'Enter' && nextEnabled) {
        onSubmitAnswer();
        e.preventDefault();
      }
    }
  }
  function getScore(right, total) {
    return (total===0)?100:Math.round(100*right/total);
  }
  function scoreClass(score) {
    var name = 'bad';
    if(score>=90)
      name = 'good';
    else if(score>=80)
      name = 'ok';
    return name;
  }
  function scorePopType(scoreClass) {
    return scoreClass;
  }
  function scoreColorName(score) {
    const scoreColors = {
      'bad':'danger',
      'ok':'warning',
      'good':'success',
    };
    const color = scoreColors[scoreClass(score)];
    assert(!!color);
    return color;
  }
  function playSound(type) {
    assert(['correct', 'wrong'].includes(type));
    const audio = new Audio(`/sound/${type}.m4a`);
    audio.volume = voice.getVolumeValue()*0.8;
    audio.play();
  }
  function onSubmitAnswer(answerOverride) {
    // if on the suggestion card, begin the lesson
    if(questionIndex === QUESTION_INDEX_SUGGESTION) {
      beginLesson();
      return;
    }
    // if on the intro card, advance into lesson
    if(questionIndex === QUESTION_INDEX_INTRO) {
      setCurrentQuestion(0);
      return;
    }
    // if on the summary card, advance to next lesson
    if(questionIndex === QUESTION_INDEX_SUMMARY) {
      getNewQuestions();
      return;
    }
    setShowHint(false);
    setRevealLevel(0);
    
    // if already answered (ie user had clicked back), advance to next question
    function advanceQuestion() {
      const nextQuestionIndex = questionIndex+1;
      const setDone = nextQuestionIndex===questionSet.questions.length;
      // for lessons
      if(!flashcardMode) {
        // if we're done w lesson
        if(setDone) {
          // submit answers
          loadingQuestions = true; // technically we're not loading questions yet but we don't want to show the answer card while we finish lesson & load next questions
          UserDataService.finishLesson({
            isFlashcards:false,
            answers:questionSet.questions.map((q, i)=>({
              wordResults:answers[i].wordResults,
              grammarId:q.grammarId,
            })),
          })
          .then(result => {
            //setUser(result.user); // setting this here will advance lesson # while we're on summary card. getting new questions (via forceUpdateLesson) will pull a new user & lessonIndex, etc will update then
            allProgress.summary = {
              total:0,
              correct:0,
            }
            questionSet.questions.forEach((q, index)=>{
              if(q.isQuiz) {
                allProgress.summary.total++;
                if(answers[index].correct)
                  allProgress.summary.correct++;
              }
            });
            const lessonRight = allProgress.summary.correct, lessonTotal = allProgress.summary.total;
            const lessonScore = getScore(lessonRight, lessonTotal);
            setQuestionIndex(QUESTION_INDEX_SUMMARY);
            popConfetti(scorePopType(scoreClass(lessonScore)));
            needFocusChange = 'next';
          })
          .catch(e => {
            console.log(e);
          });
        }
        // if we're not done w lesson
        else {
          // display next question
          setCurrentQuestion(nextQuestionIndex);
        }
      }
      // for flashcards
      else {
        // async submit answer
        const question = questionSet.questions[questionIndex];
        const answer = answers[questionIndex];
        UserDataService.finishLesson({
          isFlashcards:true,
          answers:[{
            wordResults:answer.wordResults,
            grammarId:question.grammarId,
          }],
        })
        .then(result => {
          //setUser(result.user); // we are manually updating streak after questions are answered below using setUser
          console.log('saved flashcard', result);
        })
        .catch(e => {
          console.log(e);
        });        
        // if we're done w set
        if(setDone) {
          // load next flash cards
          getNewQuestions();
        }
        // if we're not done w set
        else {
          // display next question
          setCurrentQuestion(nextQuestionIndex);
        }
      }
    }
    if(questionIndex in answers) {
      advanceQuestion();
      return;
    }
    
    // begin saving answer to question
    function makeAnswer() {
      return {};
    }
    const questionAnswer = makeAnswer();
    answers[questionIndex] = questionAnswer;
    
    // handle question types
    let right = null, toastData;
    switch(quizType) {
    case 'typeIn':
      // save answer text
      let answerText = (answer===NULL_ANSWER) ? 'newWord' : answer;
      questionAnswer.text = answerText;
      // determine what question answers we're looking for
      const questionWords = {};
      const langTo = (quizSubType==='englishToChinese') ? 'chinese' : 'english';
      let qws;
      if(langTo==='chinese') {
        qws = [...question.chinese.words]; // need to copy bc we dedupe inplace below
      }
      else {
        const wordIds = Object.values(question.english.answerKey).map(ak=>ak.wordId);
        qws = question.chinese.words.filter(cw=>wordIds.includes(cw.wordId));
      }
      arrayDedupeInplace(qws, (a,b)=>a.wordId===b.wordId);
      qws.forEach(word=>{
        let lookFor = '';
        if(langTo === 'chinese') {
          let chineseWord;
          switch(user.stage) {
          case 0:
            chineseWord = word.toneless.replaceAll('·','');
            break;
          case 1:
            chineseWord = word.tones;
            break;
          case 2:
            chineseWord = word.characters;
            break;
          default:
            assert(false);
          }
          lookFor = chineseWord.toLowerCase();
        }
        else {
          // HACK - this doesn't check words in order, just treats them as a bag of words. better to go through the answer key in order and look for words, marking right or wrong as we go (although this has challenges)
          Object.values(question.english.answerKey).filter(ak=>ak.wordId===word.wordId).forEach(ak=>{
            var adjustedEnglish = ak.english ? formatEnglish(ak.english, user) : ''; // will be missing this for certain words like mws, particles
            lookFor += adjustedEnglish+' ';
            lookFor += ak.synonyms.join(' ')+' '; // HACK - compare against synonyms' lemma form verbatim
          });
          lookFor = lookFor.toLowerCase();
        }
        questionWords[word.wordId] = {
          id:word.wordId,
          word,
          lookFor,
          englishWord:Object.values(question.english.answerKey).find(ak=>ak.wordId===word.wordId)?.english,
          found:false,
        };
      });
      // check answer words to see if they're found
      if(langTo === 'english') {
        answerText = answerText.replaceAll(`'s`, ` 's`);
        answerText = answerText.replaceAll(`' `, ` 's `);
      }
      answerText = answerText.replaceAll(`?`, ` ?`);
      const answerWords = answerText.toLowerCase().split(' ');
      answerWords.forEach(wordStr=>{
        let foundQW = Object.values(questionWords).find(qw=>(!qw.found && qw.lookFor.includes(wordStr)));
        if(!!foundQW)
          foundQW.found = true;
      });
      // build answer correctness map
      let trailingDe = false;
      let trailingGe = false;
      if(langTo === 'chinese') {
        trailingDe = question.correctAnswer.slice(-3)===' de' && question.correctAnswer.indexOf(' de ')===-1;
        trailingGe = question.correctAnswer.slice(-3)===' ge' && question.correctAnswer.indexOf(' ge ')===-1;
      }
      const wordResults = {};
      let allCorrect = true;
      Object.keys(questionWords).forEach(wordId=>{
        let correct = questionWords[wordId].found;
        if(!correct && wordId==='的de5' && trailingDe)
          correct = true;
        if(!correct && wordId==='个ge5' && trailingGe)
          correct = true;
        wordResults[wordId] = correct;
        allCorrect = allCorrect && correct;
      });
      right = allCorrect;
      const wordsTotal = Object.keys(wordResults).length;
      const wordsCorrect = Object.values(wordResults).reduce((acc, correct)=>acc+(correct?1:0), 0);
      const allWords = Object.keys(wordResults);
      // account for situations where chinese & english are different and user gets english correct, implying they understand the missing chinese words, eg particles
      if(allCorrect) {
        Object.keys(wordResults).forEach(wordId=>{
          wordResults[wordId] = true;
        });
      }
      // update user streak (on client)
      const dayToday = getStreakDay(user);
      assert(user.streakToday);
      const streakToday = user.streakToday;
      const completeField = `${!flashcardMode?'lessonQuestion':'flashcard'}sComplete`;
      const correctField = `${!flashcardMode?'lessonQuestion':'flashcard'}sCorrect`;
      streakToday.questionWordsTotal += wordsTotal;
      streakToday.questionWordsCorrect += wordsCorrect;
      streakToday.uniqueWords = arrayDedupeInplace([...(streakToday.uniqueWords??[]), ...allWords]);
      streakToday.uniqueGrammars = arrayDedupeInplace([...(streakToday.uniqueGrammars??[]), question.grammarId]);
      streakToday[completeField]++;
      if(allCorrect) {
        streakToday[correctField]++;
      }
      let streakTodayFromHistory = user.streakHistory[dayToday];
      if(!streakTodayFromHistory) {
        streakTodayFromHistory = {
          day:dayToday,
        };
        user.streakHistory[dayToday] = streakTodayFromHistory;
      }
      streakTodayFromHistory.streak = streakToday;
      setUser(user);
      // determine missed words
      const missedQWs = Object.values(questionWords).filter(qw=>!wordResults[qw.id]);
      let missedWords;
      if(langTo==='chinese')
        missedWords = missedQWs.map(qw=>qw.englishWord);
      else
        missedWords = missedQWs.map(qw=>qw.id);
      // save answers in question
      questionAnswer.missedWords = missedWords; // used to color words in answer card
      questionAnswer.wordResults = wordResults;
      const correctAnswer = question.correctAnswer.trim();
      toastData = {
        answer,
        correctAnswer,
      };
      break;
    case 'match':
      let numWrong = 0;
      for(const drop of Object.entries(dropItems)) {
        const destIndex = parseInt(drop[0]);
        const sourceIndex = drop[1].sourceIndex;
        const boxCorrect = destIndex === sourceIndex;
        if(!boxCorrect)
          numWrong++;
        dropItems[destIndex].correct = boxCorrect;
      }
      questionAnswer.dropItems = dropItems;
      right = !numWrong;
      toastData = {
        numWrong,
      };
      break;
    case 'multi':
      let answerMulti = answerOverride.multiChoice ?? multiChoice;
      questionAnswer.choice = answerMulti;
      right = answerMulti===question.answerIndex;
      let tAnswer, tCorrectAnswer;
      switch(quizSubType) {
      case 'wordToDef':
        tCorrectAnswer = lesson.usedWordData[question.wordId].defs.find(d=>d.index===question.defIndex).english;
        if(right) {
          tAnswer = tCorrectAnswer;
        }
        else {
          const choiceWord = question.options[answerMulti];
          tAnswer = lesson.usedWordData[choiceWord].defs[0].english;
        }
        break;
      case 'defToWord':
        tCorrectAnswer = lesson.usedWordData[question.wordId].toneless;
        if(right) {
          tAnswer = tCorrectAnswer;
        }
        else {
          const choiceWord = question.options[answerMulti];
          tAnswer = lesson.usedWordData[choiceWord].toneless;
        }
        break;
      case 'soundToName':
        tCorrectAnswer = question.options[question.answerIndex];
        if(right) {
          tAnswer = tCorrectAnswer;
        }
        else {
          tAnswer = question.options[answerMulti];
        }
        break;
      case 'characterToDesc':
        // correct answer would be too long so just say "you were wrong/right"
        break;
      default:
        assert(false);
      }
      toastData = {
        answer:tAnswer,
        correctAnswer:tCorrectAnswer,
      };
      break;
    default:
      // answering new word, grammar, etc questions is a no-op
      assert(!question.isQuiz);
    }
    
    // save answer to question
    if(question.isQuiz) {
      assert(right!==null && !!toastData);
      questionAnswer.correct = right;
      addAnswerToast(quizType, right, toastData);
      playSound(right?'correct':'wrong');
    }
    
    // advance
    if(!question.isQuiz || right) {
      advanceQuestion();      
    }
    else {
      needFocusChange = 'next';
      forceUpdate(); // to show wrong answer box & new toast
    }
  }
  function addAnswerToast(quizType, correct, toastData) {
    //console.log('addAnswerToast', correct, answer, correctAnswer);
    toasts.push({
      type:quizType,
      correct,
      ...toastData,
    });
    var croppedToasts = toasts.slice(-10);
    setToasts(croppedToasts);
    needToastShow = true;
  }
  
  // drag & drop
  let draggingIndex = null;
  function onBlockDragStart(ev) {
    draggingIndex = parseInt(ev.target.id.split('-')[1]);
    ev.target.style.cursor = 'grabbing';
  }
  function onBlockDragEnd(ev) {
    ev.target.style.cursor = 'default';
  }
  function questionNumNonEmptyBoxes() {
    return question.boxes.filter(b=>!!b.chinese.length).length;
  }
  function onBlockDrop(intoIndex) {
    assert(draggingIndex!==null);
    const box = question.boxes.find(b=>b.index===draggingIndex);
    const chineseWords = box.chinese.map(wordId=>lesson.usedWordData[wordId]);
    const newDropItems = {
      ...dropItems,
      [intoIndex]: {
        words:chineseWords,
        english:box.english,
        sourceIndex:box.index,
      },
    };
    setDropItems(newDropItems);
    draggingIndex = null;
    if(Object.keys(newDropItems).length===questionNumNonEmptyBoxes()) {
      setNextEnabled(true);
    }
    voice.speak(chineseWords.map(cw=>cw.characters).join(''), 'chinese');
  }
  
  // multi
  function onMultiSelect(index) {
    return ()=>{
      setMultiChoice(index);
      setNextEnabled(true);
      onSubmitAnswer({multiChoice:index});
    };
  }
  function getPlayAudioFunc(syllable) {
    return ()=>{
      voice.speak(syllable);
    };
  }
  
  // UI funcs
  function onGoBack() {
    setShowHint(false);
    setRevealLevel(0);
    if(questionIndex>0)
      setCurrentQuestion(Math.max(0, questionIndex-1));
    else if(questionSet.type==='review')
      setQuestionIndex(QUESTION_INDEX_INTRO);
  }
  function onShowHint() {
    setShowHint(true);
  }
  function onReveal() {
    setRevealLevel(revealLevel+1);
  }
  function onGrammarCheckChange(e) {
    const newGrammarOnVal = e.target.checked;
    setGrammarsOn(newGrammarOnVal);
    getNewQuestions({ wordOnly:!newGrammarOnVal });
  }
  function getGrammarPartNames(parts) {
    var names = [];
    parts.forEach(part=>{
      switch(part.type) {
      case 'grammar':
      case 'pos':
        const isGrammar = part.type==='grammar';
        const partName = isGrammar ? part.grammar.name : part.pos.name;
        names.push(partName);
        break;
      case 'operator':
        names = names.concat(getGrammarPartNames(part.parts));
        break;
      default:
        assert(false);
      }
    });
    return names;
  }
  function isItemNew(itemId) {
    const newItems = questionSet.questions.filter(q=>['word', 'grammar'].includes(q.type)).map(q=>(q.type==='word')?q.data.wordId:q.data.id); // HACK - should build this once after we load question set but it's not obvious where to stash this data, need to centralize things like progress, answers in one place
    return newItems.includes(itemId);
  }
  const newQuestionTypes = {
    'word':'Word',
    'grammar':'Grammar',
    'concept':'Concept',
    'pos':'Part of Speech',
    'theme':'Topic',
  };

  // answer card
  const showAnswer = question.isQuiz && (questionIndex in answers) && !loadingQuestions;
  let questionEnglishJsx;
  if(quizType==='typeIn' && quizSubType==='englishToChinese') {
    const questionEnglishStr = formatEnglish(question.english.text, user);
    if(!showAnswer) {
      questionEnglishJsx = questionEnglishStr;
    }
    else {
      var qeParts = [{
        right:true,
        text:questionEnglishStr.replaceAll('?',' ?'),
      }];
      answers[questionIndex].missedWords.forEach(wordText=>{
        var newQEParts = [];
        qeParts.forEach(part=>{
          if(!part.right) {
            newQEParts.push(part); // don't check inside parts that are already wrong
          }
          else {
            var splitParts;
            if(part.text.startsWith(wordText+' ')) {
              splitParts = ['', part.text.slice(wordText.length+1)];
            }
            else if(part.text.endsWith(' '+wordText)) {
              splitParts = [part.text.slice(0,part.text.length-wordText.length-1), ''];
            }
            else {
              splitParts = part.text.split(' '+wordText+' ');
            }
            splitParts = arrayIntersperse(splitParts, ()=>null);
            newQEParts = newQEParts.concat(splitParts.map(splitPart=>({
              right:splitPart!==null,
              text:(splitPart===null)?wordText:splitPart,
            })));
          }    
        });
        qeParts = newQEParts;
      });
      questionEnglishJsx = qeParts.map((part, i)=>(
        <span class={part.right?'':'text-danger'}>{part.text.trim().replaceAll(' ?', '?')}{(qeParts[i+1]?.text.trim()==='?')?'':' '}</span>
      ));
    }
  }
  
  // lesson summary
  let summaryChartData, learnedWords, learnedGrammars, wrongWords;
  const lessonDone = questionIndex === QUESTION_INDEX_SUMMARY;
  if(lessonDone) {
    // chart data
    var weekRight = 0, weekTotal = 0;
    for(var i=0; i<7; ++i) {
      const day = getStreakDay(user, i);
      const streak = user.streakHistory.find(s=>s.day===day);
      if(!!streak.streak) {
        weekRight += streak.streak.questionWordsCorrect;
        weekTotal += streak.streak.questionWordsTotal;
      }
    }
    var dayRight = user.streakToday.questionWordsCorrect, dayTotal = user.streakToday.questionWordsTotal;
    var lessonRight = allProgress.summary.correct, lessonTotal = allProgress.summary.total;
    const scores = [
      getScore(lessonRight, lessonTotal),
      getScore(dayRight, dayTotal),
      getScore(weekRight, weekTotal),
    ];
    const chartData = { // we need custom data & options for this graph bc it is not like the others
      labels:[
        'This lesson',
        'Today',
        'This week',
      ],
      datasets:[
        {
          axis:'y',
          data:scores,
          fill:false,
          backgroundColor:scores.map(s=>graphColor(scoreColorName(s), false)),
          borderColor:scores.map(s=>graphColor(scoreColorName(s))),
          borderWidth:2,
        },
      ],
    };
    const chartOptions = {
      indexAxis:'y',
      maintainAspectRatio:false,
      plugins:{
        legend:{
          display:false,
        },
        tooltip:{callbacks:{}},
      },
      scales:{
        x:{
          ticks:{},
          min:0,
          max:100,
        },
        y:{
          grid:{display:false},
          min:0,
          max:100,
        },
      },
    };
    summaryChartData = getGraphData(null, null, {
      chartDataOverride:chartData,
      chartOptionsOverride:chartOptions,
      chartType:'bar',
      yAxisType:'percent',
    });

    function getWordForDisplay(word, akEntry) {
      if(!akEntry) {
        akEntry = {
          pos:word.defs[0].pos,
          english:word.defs[0].english,
        };
      }
      assert(akEntry);
      word.id = word.wordId; // need this for word cards, and really we should be using id anyway
      word.english = akEntry.english;
      word.pos = akEntry.pos;
      word.chinese = [word];
      return word;
    }
    learnedWords = lesson.questions.filter(q=>q.type==='word').map(q=>{
      const word = q.data;
      var firstAk;
      for(var i=0; i<lesson.questions.length && !firstAk; ++i) {
        firstAk = Object.values(lesson.questions[i].english?.answerKey ?? {}).find(ak=>ak.wordId===word.wordId);
      }
      return getWordForDisplay(word, firstAk);
    });
    const grammarItemEmojis = {
      'grammar':'🏗️',
      'shard':'➕',
      'pos':'🧱',
    };
    learnedGrammars = lesson.questions.filter(q=>Object.keys(grammarItemEmojis).includes(q.type)).map(q=>({
      type:q.type,
      emoji:grammarItemEmojis[q.type],
      name:q.data.name,
    }));
    wrongWords = [];
    lesson.questions.forEach((q, i)=>{
      const wordResults = answers[i].wordResults;
      Object.keys(wordResults ?? {}).filter(wordId=>!wordResults[wordId]).forEach(wordId=>{
        const word = q.chinese.words.find(w=>w.wordId===wordId);
        var ak = Object.values(q.english.answerKey).find(ak=>ak.wordId===wordId);
        wrongWords.push(getWordForDisplay(word, ak));
      });
    });
    arrayDedupeInplace(wrongWords, (a,b)=>a.wordId===b.wordId);
  }

  // lesson intro
  let reviewIntro;
  const lessonIntro = questionIndex === QUESTION_INDEX_INTRO;
  if(lessonIntro) {
    const streakMonth = user.streakHistory.map(so=>so.streak);
    const {fluencyNumbers} = getFluencyData(user, streakMonth);
    const fluencyTrend = getFluencyTrend(fluencyNumbers);
    let direction = fluencyTrend, exclamation='';
    switch(fluencyTrend) {
    case 'up':
      exclamation = ' Good job!';
      break;
    case 'down':
      exclamation = ' You need some practice!';
      break;
    case 'flat':
      // no exclamation
      break;
    default:
      assert(false);
    }
    reviewIntro = `Your fluency levels have trended ${direction} over the last month.${exclamation}`;
  }
  
  // suggestion
  const onSuggestion = questionIndex === QUESTION_INDEX_SUGGESTION;
  let suggestion;
  if(onSuggestion) {
    suggestion = questionSet.suggestion;
    let title, body, actionButtons;
    switch(suggestion.type) {
    case 'practice':
      title = 'Recommended practice';
      actionButtons = [
        {
          text:'See my stats',
          action:()=>{
            navigate('/progress');
          },
        },
        {
          text:'Practice',
          action:()=>{
            navigate('/practice');
          },
        },
      ];
      body = [
        `Your fluency level has not been trending up lately. Below is a summary of your fluency stats. If you want to practice your weakest words, click '`,
        <strong>{actionButtons[1].text}</strong>,
        `'.`,
      ];
      break;
    case 'curriculum':
      title = 'Personal curriculum';
      actionButtons = [
        {
          text:'See more lessons',
          action:()=>{
            navigate('/journey');
          },
        },
        {
          text:'Personalize my curriculum',
          action:()=>{
            navigate('/journey/customize');
          },
        },
      ];
      body = [
        `Are you happy with what you've been learning? Below are your next ${suggestion.journeyItems.length} lessons. If there are some particular words you'd rather learn, click '`,
        <strong>{actionButtons[1].text}</strong>,
        `'.`,
      ];
      break;
    default:
      assert(false);
    }
    suggestion = {
      ...suggestion,
      title,
      body,
      actionButtons,
    };
  }
      
  // render
  return (
    <> 
    {/* question page header */}
    <SubHeader headerRef={props.headerRef}>
      <div class="row">
        { !flashcardMode ?
        <div class="col d-flex">
          <div class="text-start fw-medium">
          Level {user.level+1}, Lesson {user.lessonIndexActual+1}
          </div>
          <div class="text-center flex-grow-1 px-4">
            <div className="progress h-100" role="progressbar">
              <div className="progress-bar bg-info" style={{width:`${(100*(lessonDone ? 1 : (questionIndex+1)/questionSet.questions.length))}%`}}></div>
            </div>
          </div>
        </div>
        : !!practiceGrammarId ?
        <div class="col">
          Practicing grammmar - <strong style={{'text-transform':'capitalize'}}>{questionSet?.grammarForFE?.name}</strong>
        </div>
        :
        <div class="col">
          <div class="form-check form-switch">
            <input class="form-check-input" style={{cursor:'pointer'}} type="checkbox" role="switch" id="flexSwitchCheckDefault" onChange={onGrammarCheckChange} checked={grammarsOn} />
            <label class="form-check-label" style={{cursor:'pointer'}} for="flexSwitchCheckDefault">Include Grammars</label>
          </div>
        </div>
        }
      </div>
    </SubHeader>
    <div class="p-5 panel mt-4" style={flashcardMode?{boxShadow:`8px 8px 0 0px white,
      10px 6px 0 0px #eee,
      6px 10px 0 0px #eee,
      10px 10px 0 0px #eee`, border:`2px solid #eee`}:{}}>
      
      {/* new item cards */}
      { (question.type in newQuestionTypes) &&
      <>
        { !(question.type==='concept' && question.data.id==='welcome') &&
          <h4 class="text-success mb-3">New {newQuestionTypes[question.type]}!</h4>
        }
        <div class="mb-5">
          <LessonCard type={question.type} itemData={question.data} usedWordData={questionSet.usedWordData} user={user} class="mb-5" />
        </div>
      </>
      }
      { (question.type==='shard') &&
      <>
        <h4 class="text-success mb-3">Updated Grammar!</h4>
        <div class="mb-5">
          <LessonCard type="grammar" itemData={question.data} usedWordData={questionSet.usedWordData} user={user} class="mb-5" />
        </div>
      </>
      }
      { (question.type==='slu') &&
      <>
        <h4 class="text-success mb-3">New {getSLUTypeName(question.sluType)}!</h4>
        <div class="mb-5">
          <LessonCard type={question.sluType} slu={questionSet[question.sluType]} usedWordData={questionSet.usedWordData} user={user} class="mb-5" />
        </div>
      </>
      }

      {/* intro card */}
      { lessonIntro &&
      <>
        <h1>Vocabulary Review</h1>
        <div class="mt-3 text-muted fs-4 mb-4">{reviewIntro} This lesson will review your weakest words &amp; grammars.</div>
        
        { renderFluencySummary(user, lesson.fluencySummaryData) }
      </>
      }

      {/* suggestion card */}
      { suggestion &&
      <>
        <h1>{suggestion.title}</h1>
        <div class="mt-3 text-muted fs-4 mb-4">
          { suggestion.body }
        </div>
        { suggestion.type==='practice' &&
          <div class="mb-5">
            { renderFluencySummary(user, lesson.fluencySummaryData) }
          </div>
        }
        { suggestion.type==='curriculum' && 
          suggestion.journeyItems.map(item=>renderJourneyItem(user, item))
        }
      </>
      }

      {/* free response quiz */}
      { (quizType==='typeIn') &&
      <>
        {/* special lesson title (optional) */}
        { (questionSet.type==='practical') &&
          <h4 class="text-success mb-3">Practical Lesson - {questionSet.theme.name}</h4>
        }
        {/* question prompt */}
        { (quizSubType==='englishToChinese') ?
        <>
          <h4 class="text-muted">Type in {questionSet.stage.name}:</h4>
          <h1 class="mt-2 fw-bold">
            { questionEnglishJsx }
          </h1>
        </>
        :
        <>
          <h4 class="text-muted">Type in English:</h4>
          <h1 class="mt-2 fw-bold">
            <ChinesePhrase words={question.chinese.words} showStats={true} markWrong={showAnswer?answers[questionIndex].missedWords:[]} user={user} />
          </h1>
        </>
        }
        {/* input box */}
        <input class="form-control mt-4" onChange={onChangeAnswer} value={answer} onKeyDown={onKeyDown} disabled={questionIndex in answers} ref={inputBoxRef} autoFocus/>
        {/* hint & correct answer box (shown if user goes back or gets it wrong) */}
        { (showAnswer || showHint) &&
        <div class={`alert alert-${showHint?'light':(answers[questionIndex].correct?'success':'danger')} mt-4`} role="alert">
          { !showHint &&
          <>
            <h4 class="alert-heading">You were {answers[questionIndex].correct?'correct':'incorrect'}</h4>
            {!answers[questionIndex].correct &&
              <p>The correct answer was: <strong>{quizSubType==='englishToChinese' ? question.chinese.words.map(word=>word.toneless).join(' ').replaceAll(' ?', '?') : formatEnglish(question.english.text, user)}</strong></p>
            }
            <hr/>
          </>
          }
          <div class="position-relative">
            { showHint && (revealLevel<question.chinese.words.length) && 
              <button class="btn btn-action btn-secondary position-absolute" style={{right:0}} onClick={onReveal}>Reveal {!revealLevel?'one':'more'}</button>
            }
            <p>The words in the question {showHint?'are':'were'}:</p>
            <ul>
              {question.chinese.words.map((word, index)=>
              <li key={index} style={{filter:(showHint && index>=revealLevel)?`blur(8px)`:``}}>
                <Link to={getDictionaryPath('word', word.wordId)} style={!isItemNew(word.wordId)?{color:'inherit'}:disabledLinkStyles}><strong>{word.toneless}</strong></Link>: {formatEnglish(word.defs[word.defs.findIndex(d=>d.pos===word.pos)].english, user, word.pos, word.isProper)}
              </li>
              )}
            </ul>
          </div>
          { !!question.grammarForFE &&
          <>
            <hr/>
            <p>The <Link to={getDictionaryPath('grammar', question.grammarForFE.id)} style={!isItemNew(question.grammarForFE.id)?{color:'inherit'}:disabledLinkStyles}><strong style={{'text-transform':'capitalize'}}>{question.grammarForFE.name}</strong></Link> grammar {showHint?'is':'was'} used, which may include:</p>
            <ul class="mb-1">
              {getGrammarPartNames(question.grammarForFE.parts).map((elt, index)=>
              <li key={index}>{elt}</li>
              )}
            </ul>
          </>
          }
        </div>   
        }
      </>
      }
      
      {/* matching blocks quiz */}
      { (quizType==='match') &&
      <>
        <h4 class="text-success mb-3">{lesson.grammarForFE.name}</h4>
        <h4 class="text-muted">Match the word blocks into the correct grammar boxes:</h4>
        <div class="row mt-4">
          { getGrammarVisual(lesson.grammarForFE, {dropCb:onBlockDrop, dropItems}) }
        </div>
        { !nextEnabled &&
          <hr class="my-4" />
        }
        <div class="row">
          { question.boxes.filter(b=>!Object.values(dropItems).some(di=>di.sourceIndex===b.index) && b.chinese.length>0).map(box=>
            <div class={`col-4 p-2`} key={box.index}>
              <div class="panel-inset fs-4 px-4 py-2" draggable="true" id={`dragBox-${box.index}`} onDragStart={onBlockDragStart} onDragEnd={onBlockDragEnd} style={{cursor:'grab'}}>
                <div class="text-black"><ChinesePhrase words={box.chinese.map(wordId=>lesson.usedWordData[wordId])} user={user} /></div>
                { !!box.english &&
                  <div class="text-muted"> → {box.english}</div>
                }
              </div>
            </div>
          ) }
        </div>
      </>
      }
    
      {/* multi choice quiz */}
      { (quizType==='multi') &&
      <>
        { (quizSubType==='wordToDef' || quizSubType==='defToWord') && 
          <h4 class="text-muted">Select the correct translation of:</h4>
        }
        { (quizSubType==='soundToName' || quizSubType==='characterToDesc') && 
          <h4 class="text-muted">Select the correct sound of:</h4>
        }
        <h1 class="mt-2 fw-bold">
          { quizSubType==='wordToDef' && 
            <ChinesePhrase words={[lesson.usedWordData[question.wordId]]} showStats={true} user={user} />
          }
          { quizSubType==='defToWord' && 
            lesson.usedWordData[question.wordId].defs.find(d=>d.index===question.defIndex).english
          }
          { quizSubType==='characterToDesc' && 
            question.syllableToneless
          }
          { quizSubType==='soundToName' && 
            <div class="panel-inset fs-2 px-4 py-3 my-3 d-inline-block" style={{cursor:'pointer'}} onClick={getPlayAudioFunc(question.syllableCharacter)}>
              <i class="bi bi-play-circle-fill btn-action d-inline-block text-primary me-2"></i> Play syllable
            </div>
          }
        </h1>

        <div class="row">
          { question.options.map((option, i)=>
            <div class={`col-6 p-2 border border-3 border-${(multiChoice===i)?(showAnswer?((i===question.answerIndex)?'success':'danger'):'secondary'):'light'}`} key={i} style={{cursor:'pointer'}} onClick={showAnswer?null:onMultiSelect(i)}>
              <div class="panel-inset fs-4 px-4 py-2" id={`multi-${i}`}>
                { quizSubType==='defToWord' && 
                  <ChinesePhrase words={[lesson.usedWordData[option]]} showStats={true} user={user} />
                }
                { quizSubType==='wordToDef' && 
                  <div class="text-black">{lesson.usedWordData[option].defs.find(d=>(i===question.answerIndex)?d.index===question.defIndex:true).english}</div>
                }
                { (quizSubType==='soundToName' || quizSubType==='characterToDesc') && 
                  <div class="text-black">{option}</div>
                }
              </div>
            </div>
          ) }
        </div>
      </>
      }
      
      {/* lesson complete (part 1)*/}
      { lessonDone &&
      <>
        <div class="row">
          <div class="col-md-8">
            <h4 class="text-success mb-3">Lesson complete!</h4>
            <h1>Level {user.level+1}, Lesson {user.lessonIndexActual+1}</h1>
            { (allProgress.summary.total>0) &&
            <div class="mt-3 text-muted fs-4 mb-4">You got <span class="text-success">{allProgress.summary.correct}</span> questions correct out of {allProgress.summary.total}</div>              
            }
          </div>
          <div class="col-md-4">
            <span class={`badge bg-${scoreColorName(getScore(allProgress.summary.correct,allProgress.summary.total))} float-end fs-1`}>{getScore(allProgress.summary.correct,allProgress.summary.total)}%</span>
          </div>
        </div>
        
        <div class="row mb-4"><div class="col-12">
          <Graph user={user} data={summaryChartData} />
        </div></div>
      </>
      }
      
      {/* question page footer */}
      <div class="row mt-3 justify-content-end">
        <div class="col text-end">
          {!showHint && (quizType==='typeIn') && 
            <button class="btn btn-action btn-outline-secondary me-4" onClick={onShowHint}>Hint</button>
          }
          { !!suggestion && suggestion.actionButtons.map(button=>(
            <button class="btn btn-action btn-outline-secondary me-2" onClick={button.action}>{button.text}</button>
          )) }
          { ((!!questionIndex || questionSet.type==='review') && questionIndex!==QUESTION_INDEX_INTRO && questionIndex!==QUESTION_INDEX_SUGGESTION && !lessonDone) &&
            <button class="btn btn-action btn-secondary me-2" onClick={onGoBack}>Back</button>
          }
          <button class="btn btn-action btn-primary" disabled={!lessonDone && !nextEnabled} onClick={onSubmitAnswer} onKeyDown={onKeyDown} ref={nextBtnRef}>
            { questionIndex===QUESTION_INDEX_SUGGESTION ?
              <>Just continue</>
              :
              <>Next</>
            }
          </button>
        </div>
      </div>
    </div>

    {/* lesson complete (part 2)*/}
    { lessonDone &&
    <div class="p-5 panel mt-4">
      { !!learnedWords.length && 
      <div class="row mb-4"><div class="col-12">
        <h3 class="mb-3 fw-light">Words you learned</h3>
        <div class="row">
          { learnedWords.map(word=>
          <WordCard {...{
            user, word,
            colSize:6,
            fontSize:2,
            linkDisabled:true,
          }} />
          ) }
        </div>
      </div></div>
      }
      
      { !!learnedGrammars.length && 
      <div class="row mb-4"><div class="col-12">
        <h3 class="mb-3 fw-light">Grammars you learned</h3>
        <div class="row">
          { learnedGrammars.map(item=>
          <div class={`col-6 p-2`}>
            <div class="panel-inset fs-2 px-4 py-2">
              <span class={`me-3`}>{item.emoji}</span>
              <span class="text-black">{item.name}</span>
            </div>
          </div>
          ) }
        </div>
      </div></div>
      }
      
      { !!wrongWords.length &&
      <div class="row mb-4"><div class="col-12">
        <h3 class="mb-3 fw-light">Words you missed</h3>
        <div class="row">
          { wrongWords.map(word=>
          <WordCard {...{
            user, word,
            colSize:6,
            fontSize:2,
            linkDisabled:learnedWords.find(lw=>lw.id===word.id), // don't allow click on new words
          }} />
          ) }
        </div>
      </div></div>
      }
      
      <div class="row">
        <div class="col-12">
          <h3 class="mb-3 fw-light">{lesson.stage.name} progress</h3>
          <div class="row p-2">
            <div class="col panel-inset px-4 py-3 pb-4">
              <div class="row">
                <div class="col fs-4 text-muted">
                  <span>Level {user.level+1} - {lesson.level.name}</span>
                  <span class="badge bg-success float-end mt-1">{Math.ceil(100*(user.lessonIndexActual+1)/lesson.level.numLessons)}%</span>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <div className="progress mt-3" role="progressbar">
                    <div className="progress-bar bg-info" style={{width:`${100*(user.lessonIndexActual+1)/lesson.level.numLessons}%`}}></div>
                  </div>
                </div>
              </div>
            </div>
          </div>                
          <div class="mt-3 text-muted fs-4">
            <p class="mb-0">This level has <strong>{lesson.level.numLessons} lessons</strong> including <strong>{lesson.level.totalWords} words</strong> and <strong>{lesson.level.totalGrammars} grammars</strong>.</p>
            <p>{lesson.level.descShort}</p>
          </div>
          { showExtraTabs(user) &&
          <div class="row"><div class="col-4">
            <Link to="/journey"><button class="btn btn-action btn-outline-secondary w-100 mt-4">See progress</button></Link>
          </div></div>
          }
        </div>
      </div>
    </div>
    }
    
    {/* toasts container */}
    <div class="toast-container position-fixed bottom-0 end-0 p-3">
      { toasts.map((toast, index)=>(
      <div class={`toast align-items-center text-bg-${toast.correct?'success':'danger'} border-0`} role="alert" aria-live="assertive" aria-atomic="true" ref={el => toastsRef.current[index] = el} key={index}>
        <div class="d-flex">
          <div class="toast-body">
            { (toast.type==='typeIn' || toast.type==='multi') &&
            <>
              {toast.answer?`'${toast.answer}'`:'Your answer'} was { toast.correct ?
              <b>correct</b>
              :
              <><b>incorrect</b>{toast.correctAnswer?`, the answer was '${toast.correctAnswer}'`:''}</>
              }
            </>
            }
            { (toast.type==='match') &&
            <>
              You matched { toast.correct ?
                <>all boxes <b>correctly</b></>
              :
                <>{toast.numWrong} boxes <b>incorrectly</b></>
              }
            </>
            }
          </div>
          <button type="button" class="btn-close btn-close-white me-2 m-auto" data-bs-dismiss="toast" aria-label="Close"></button>
        </div>
      </div>
      ))}
    </div>
    </>    
  );
}