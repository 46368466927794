import { useState } from "react";
import { useNavigate } from "react-router-dom";

import useData from "../hooks/data.hook";

import UserDataService from "../services/user.service";

import SubHeader from "./subHeader.component";
import Loading from "./loading.component";
import ChinesePhrase from "./chineseWord.component";

import { assert, objectDeleteData, formatEnglish } from '../common/utility';

export default function JourneyCustomize({headerRef}) {  
  const { user } = useData('user');

  const [ words, setWords ] = useState('');
  const [ mode, setMode ] = useState('entry');
  const [ resolveResponse, setResolveResponse ] = useState(null);
  const [ selectedWords, setSelectedWords ] = useState([]);

  const navigate = useNavigate();

  // return if we haven't loaded data yet
  if(!user) {
    return (<Loading />);
  }
  
  // actions
  function handleChange(e) {
    const {value} = e.target;
    setWords(value);
  }
  function submit() {
    setMode('submitting');
    const data = {
      requestStr:words,
    };
    UserDataService.updateJourney(data)
    .then(result => {
      console.log('update journey', result);
      setResolveResponse(result.result);
      setMode('resolve');
    })
    .catch(e => {
      console.error(e);
    });
  }
  function done() {
    function finish() {
      navigate('/journey');
    }
    if(!selectedWords.length)
      finish();
    
    setMode('submitting');
    const data = {
      wordIds:selectedWords,
    };
    UserDataService.updateJourneyResolve(data)
    .then(result => {
      console.log('update journey resolve', result);
      finish();
    })
    .catch(e => {
      console.error(e);
    });
  }

  // prep resolve data
  let resolveSectionsDisplay;
  if(resolveResponse) {
    const resolveSections = [
      {
        type:'ambiguous',
        subheader:'Some words you requested have multiple definitions. Please select which of the following you would like to learn',
      },
      {
        type:'found',
        subheader:'These words were found and added to your curriculum',
      },
      {
        type:'known',
        subheader:'You already know these words',
      },
      {
        type:'notFound',
        subheader:'These words were not found',
      },
    ];
    resolveSectionsDisplay = [];
    for(const rs of resolveSections) {
      const resolvesOfType = resolveResponse.response.filter(r=>r.type===rs.type);
      if(resolvesOfType.length) {
        function arrayCoerce(arr) {
          return Array.isArray(arr) ? arr : [arr];
        }
        let words = [];
        for(const resolve of resolvesOfType) {
          words = words.concat(...arrayCoerce(resolve.word ?? resolve.english ?? resolve.knownWords ?? resolve.possibleWords));
        }
        resolveSectionsDisplay.push({
          ...rs,
          words,
        });
      }
    }
  }
  
  // resolve helpers
  function onSelect(wordId) {
    return ()=> {
      if(selectedWords.includes(wordId))
        setSelectedWords(selectedWords.filter(w=>w!==wordId));
      else
        setSelectedWords([...selectedWords, wordId]);
    };
  }
  function getWordForDisplay(wordDef) {
    let word = resolveResponse.usedWordData[wordDef.wordId];
    const def = word.defs[wordDef.defIndex];
    assert(def);
    word = {...word, ...def};
    objectDeleteData(word, ['pinyinBreakdown', 'level', 'defs', 'englishFull', 'index', 'posName']);
    word.chinese = [word];
    return word;
  }
  function wordCard(wordDef) {
    const word = getWordForDisplay(wordDef);
    const isSelected = selectedWords.includes(word.wordId);
    return (
      <div class={`panel-inset bg-${isSelected?'success-subtle':'white'} fs-2 px-4 py-2`}>
        <span class="text-black"><ChinesePhrase words={word.chinese} user={user} /></span>
        <span class="text-muted"> → {formatEnglish(word.english, user, word.pos, word.isProper)}</span>
      </div>
    );
  }
  
  // render
  const submitting = mode==='submitting';
  //console.log('Journey customize - render', user);
  return (<>
    <SubHeader headerRef={headerRef}>
    </SubHeader>
    
    <div class="p-5 panel">
      <h1 class="mb-4">Customize your journey</h1>
      { (mode==='entry' || mode==='submitting') &&
        <>
        <div class="mt-3 text-muted fs-4 mb-4">
          Enter, in English, some words you&apos;d like to learn
        </div>
        <div class="row mt-2"><div class="col">
          <textarea class="form-control" rows="4" id="words" onChange={handleChange} {...{disabled:submitting}} />
        </div></div>
        <div class="mt-4 text-end">
          <button class="btn btn-action btn-primary" {...{disabled:!words || submitting}} onClick={submit}>Submit</button>
        </div>
        </>
      }
      { mode==='resolve' && 
        <div>
          {resolveSectionsDisplay.map(rs=>
            <div class="mt-4">
              <div class="text-muted fs-4 mb-2">
                {rs.subheader}
              </div>
              <div class="row">
                { rs.words.map(word=>( rs.type==='notFound' ?
                  <div class={`col-6 p-2`}>
                    <div class="panel-inset fs-2 px-4 py-2">
                      <span class="text-black">{word}</span>
                    </div>
                  </div>
                :
                  <div class={`col-6 p-2`} key={word.wordId}>
                    <div class={`p-2 border border-3 border-${selectedWords.includes(word.wordId)?'success':'light'}`} style={{cursor:rs.type==='ambiguous'?'pointer':'default'}} onClick={rs.type==='ambiguous'?onSelect(word.wordId):null}>
                      { wordCard(word) }
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}
          <div class="mt-4 text-end">
            <button class="btn btn-action btn-primary" {...{disabled:submitting}} onClick={done}>Done</button>
          </div>
        </div>
      }
    </div>
  </>);
}