import { useState, useRef } from "react";
import { useLocation } from "react-router-dom";

import FeedbackService from "../services/feedback.service";

import useTimer from "../hooks/timer.hook";

export default function Feedback() {
  const page = useLocation();
  const timer = useTimer();

  const [show, setShow] = useState(false);
  const [sayThanks, setSayThanks] = useState(false);
  function toggleDisplay() {
    setShow(!show);
  }
  
  const [submitting, setSubmitting] = useState(false);
  const inputBoxRef = useRef();
  const [state , setState] = useState({
    feedback: '',
  });
  const handleChange = (e) => {
    const {id , value} = e.target;
    setState(prevState => ({...prevState, [id]:value}));
  };
  function submitFeedback() {
    setSubmitting(true);
    const data = {
      text:state.feedback,
      page:page.pathname,
    };
    FeedbackService.submitFeedback(data)
      .then(() => {
        setSubmitting(false);
        setState({feedback:''});
        inputBoxRef.current.value = '';
        setSayThanks(true);
        timer.fn(() => {
          setSayThanks(false);
        }, 3000);
      })
      .catch(e => {
        console.log(e);
      });
  }

  return (
    <div style={{position:'fixed', left:20, bottom:20, width:'100%'}}>
      <button class="btn btn-primary" type="button" style={{position:'absolute', left:0, bottom:0, display:!show?'block':'none'}} onClick={toggleDisplay}>
        <i class="bi bi-chat-left-text-fill"></i>
      </button>
      <div class="card col-md-4" style={{position:'absolute', left:0, bottom:0, display:show?'block':'none'}}>
        <h5 class="card-header">
          Feedback
          <button type="button" class="btn-close" aria-label="Close" style={{float:'right', height:'0.7em'}} onClick={toggleDisplay}></button>
        </h5>
        <div class="card-body">
          <form>
            <textarea class="form-control mb-2" rows="3" disabled={submitting} id="feedback" onChange={handleChange} ref={inputBoxRef}></textarea>
            <button className="btn btn-primary" onClick={submitFeedback} disabled={submitting || !state.feedback}>Submit</button>
            { sayThanks &&
            <div class="text-success ms-3 d-inline-block">
              <span style={{position:'relative', top:3}}>Thank you</span>
            </div>
            }
          </form>
        </div>
      </div>
    </div>
  );
}

