import { useEffect } from "react";
import { Link } from "react-router-dom";
import { useLocalStorage } from "@uidotdev/usehooks";
import 'animate.css';

import { capitalizeFirstLetter, renderProfTable,
  getFluencyTrend, getFluencyData,
  getStreakDay,
  arrayGetRandomItem,
} from '../common/utility';

import SubHeader from "./subHeader.component";
import Loading from "./loading.component";
import { getGraphData, getFluencyGraphData, Graph } from './graph.component';

import useData from "../hooks/data.hook";
import useTimer from "../hooks/timer.hook";

export default function Progress(props) {
  const { user } = useData('user');
  const { progress, forceReloadProgress } = useData('progress');
  
  const [ vocabRangeXUnits ] = useLocalStorage('vocabRangeXUnits', 'days');
  const [ grammarRangeXUnits ] = useLocalStorage('grammarRangeXUnits', 'days');
  const [ showMore, setShowMore ] = useLocalStorage('showMore', false);
  
  const timer = useTimer();

  // reload when user switches to tab
  useEffect(() => {
    forceReloadProgress();
  // eslint-disable-next-line
  }, []); // run once on component creation

  // hero animation
  function onHeroMascotRender(node) {
    const heroMascotRef = node;
    let animClasses;
    function animationEnd(event) {
      event.stopPropagation();
      heroMascotRef.classList.remove(...animClasses);
      timer.fn(() => {
        fireHeroAnimation();
      }, 5000);
    }
    function fireHeroAnimation(optionOverride=null) {
      if(!heroMascotRef)
        return;
      const animOptions = ['bounce', 'tada', 'headShake'];
      const option = optionOverride ?? arrayGetRandomItem(animOptions);
      animClasses = ['animate__animated', `animate__${option}`];
      heroMascotRef.classList.add(...animClasses);
      heroMascotRef.addEventListener('animationend', animationEnd, {once:true});
    }
    fireHeroAnimation('bounceIn');
  }

  // show loading if data is not loaded
  if(!user || !user.streakHistory || !progress) {
    return (<Loading />);
  }

  // get overview data
  const graphs = {};
  let heroClosingStatement, heroButton;
  let studyDays=0, streakDays=0, heroFlashcards=0, heroLessons=0;
  
  // hero text
  const streakMonth = user.streakHistory.map(so=>so.streak);
  const {fluencyNumbers} = getFluencyData(user, streakMonth);
  const fluencyTrend = getFluencyTrend(fluencyNumbers);
  const userJoinedDaysAgo = (Date.now()-Date.parse(user.createdAt))/1000/86400;
  heroButton = {
    text:'Learn',
    link:'/lesson',
  };
  if(userJoinedDaysAgo<7) {
    heroClosingStatement = `You're just getting started!`;
  }
  else {
    if(fluencyTrend==='down') {
      heroClosingStatement = [`Your fluency is trending `, <strong class="fw-bold">down</strong>, `. You need some practice!`];
      heroButton = {
        text:'Practice',
        link:'/practice',
      };
    }
    else {
      heroClosingStatement = [`Your fluency is trending `, <strong class="fw-bold">up</strong>, `. Time to learn some new words!`];
    }
  }
    
  // hero stats
  const flashcardHistory = [], lessonHistory = [];
  user.streakHistory.forEach(streak=>{ // start counting from 30 days ago toward present (user.streakHistory is sorted)
    let flashcardVal=0, lessonVal=0;
    if(streak?.streak && !streak.streak.isEmpty) {
      streak = streak.streak;
      flashcardVal = streak.flashcardsComplete;
      lessonVal = streak.lessonQuestionsComplete;
      heroFlashcards += flashcardVal;
      heroLessons += streak.lessonsComplete;
      if(flashcardVal || lessonVal)
        studyDays++;
      streakDays++;
    }
    else {
      if(streak.day===getStreakDay(user))
        streakDays++;
      else
        streakDays=0;
    }
    flashcardHistory.push(flashcardVal);
    lessonHistory.push(lessonVal);
  });
  const allStudyDays = progress.streakData.filter(s=>!s.isEmpty).length;
  
  // fluency graph
  const sortedStreakData = progress.streakData.sort((a,b)=>(new Date(a.day))-(new Date(b.day)));
  graphs.vocabFluency = getFluencyGraphData(user, sortedStreakData, {
    unitSelectorName:'vocabFluencyXUnits',
  });
  //const wordsFluent = graphs.vocabFluency.dataSets[0].at(-1);
  
  // get data for "show more"
  if(showMore) {
    // streak graph
    if(studyDays>0) {
      const labels = user.streakHistory.map(streak=>streak.day); // just last 30 days
      graphs.streak = getGraphData(labels, [flashcardHistory, lessonHistory], { // from hero stats above
        yAxisLabel:'Questions / day',
        dataSetLabels:[
          'Flashcards',
          'Lesson questions',
        ]
      });
    }
    // range graphs
    const labels = sortedStreakData.map(sd=>sd.day);
    function avoidNaN(num) {
      return (isNaN(num) || num===Infinity) ? 0 : num;
    }
    graphs.vocab = getGraphData(labels, [sortedStreakData.map(sd=>sd.uniqueWords.length)], {
      unitSelectorName:'vocabRangeXUnits',
      yAxisLabel:`Unique words / ${vocabRangeXUnits.slice(0,-1)}`,
    });
    graphs.grammar = getGraphData(labels, [sortedStreakData.map(sd=>sd.uniqueGrammars.length)], {
      unitSelectorName:'grammarRangeXUnits',
      yAxisLabel:`Unique grammars / ${grammarRangeXUnits.slice(0,-1)}`,
    });
    graphs.sentence = getGraphData(labels, [sortedStreakData.map(sd=>avoidNaN(sd.questionWordsTotal/(sd.lessonQuestionsComplete+sd.flashcardsComplete)))], {
      unitSelectorName:'sentenceRangeXUnits',
      yAxisLabel:`Words`,
    });
    // mastery graph
    graphs.grammarCorrectness = getGraphData(labels, [sortedStreakData.map(sd=>Math.floor(100*avoidNaN((sd.flashcardsCorrect+sd.lessonQuestionsCorrect)/(sd.flashcardsComplete+sd.lessonQuestionsComplete))))], {
      yAxisType:'percent',
    });
  }
  //console.log('graph data', graphs);
  
  // special render funcs
  function numberAndUnits(num, units, before='', after='') {
    return <>{before}<strong class="fw-bold">{num} {units}{num!==1?'s':''}</strong>{after}</>;
  }
  function numberBox(colSize, num, title, xSpacing='pe-5') {
    return (
    <div class={`col-md-${colSize} p-2 ${xSpacing}`}>
      <div class="text-center panel-inset p-1 py-4">
        <div class="fs-1 fw-medium text-info">{num}</div>
        <div class="text-muted">{title}</div>
      </div>
    </div>      
    );
  }
  
  // render
  // eslint-disable-next-line
  return (
    <>
      <SubHeader headerRef={props.headerRef}>
      </SubHeader>
    
      <h1 class="mb-4 text-secondary">Welcome, {capitalizeFirstLetter(user.fname)} 🥡🥢</h1>
    
      <div class="panel p-5 mb-4 row">
        <div class="col-7">
          <h3 class="mb-3 fw-light lh-sm">In the past 30 days you have studied {numberAndUnits(studyDays,'day')} with {numberAndUnits(heroLessons, 'lesson')} and {numberAndUnits(heroFlashcards, 'flashcard')} completed{heroClosingStatement?<span>. {heroClosingStatement}</span>:''}</h3>
          <Link to={heroButton.link} class="float-end"><button class="btn btn-action btn-primary btn-lg px-4">{heroButton.text}</button></Link>
        </div>
        <div class="col-4 ms-5 text-center">
          <img src="/images/mascot/heroPanda.svg" style={{width:'90%'}} ref={onHeroMascotRender} />
        </div>
      </div>

      <div class="panel p-5 mb-5 row">
        <div class="col">

          <h3 class="fw-light mb-3">Stats</h3>
          <div class="row">
            {numberBox(3, progress.totalWordsLearned, 'Words', 'px-25 ps-2')}
            {numberBox(3, progress.totalGrammarsLearned, 'Grammars', 'px-25')}
            {numberBox(3, allStudyDays ? (progress.totalWordsLearned/allStudyDays).toFixed(2) : 0, 'New words / day', 'px-25')}
            {numberBox(3, streakDays, 'Streak days', 'px-25 pe-2')}
          </div>

          { (studyDays>0) &&
            <Graph className="mt-5 mb-3" user={user} data={graphs.vocabFluency} titleContent={
              <h3 class="fw-light">Fluency</h3>
            } />
          }

          { !showMore && (studyDays>0) &&
            <button class="btn btn-action btn-primary float-end" onClick={()=>{setShowMore(true)}}>See more</button>
          }

          { showMore &&
            <>
            <h3 class="fw-light mb-3 mt-5">Streak</h3>
            <Graph className="" user={user} data={graphs.streak} />
            </>
          }
        </div>
      </div>
      
      { showMore && 
        <>
        { /* Range */ }

        <div class="mb-5">
          <h3 class="mb-3">Range</h3>

          <div class="panel p-5 row">
            <Graph className="mb-5" user={user} data={graphs.vocab} titleContent={
              <>
              <h3 class="fw-light">Vocabulary range</h3>
              <div class="text-muted pt-2 fs-5">To develop a rich vocabulary, use as many different words as possible each week</div>
              </>
            } />

            <Graph className="mb-5" user={user} data={graphs.grammar} titleContent={
              <>
              <h3 class="fw-light">Grammar range</h3>
              <div class="text-muted pt-2 fs-5">To learn to communicate more ideas, practice sentences with more complex grammar</div>
              </>
            } />

            <Graph user={user} data={graphs.sentence} titleContent={
              <>
              <h3 class="fw-light">Sentence length</h3>
              <div class="text-muted pt-2 fs-5">The ability to use longer sentences is an indicator of fluency</div>
              </>
            }/>
          </div>
  
        </div>
                
        { /* Mastery */ }
  
        <div>
          <h3 class="mb-3">Mastery</h3>

          <div class="row panel p-5">
            { graphs.grammarCorrectness &&
            <div class="col-12">
              <div class="mb-5">
                <h3 class="fw-light">Lesson performance</h3>

                <Graph className="mt-4" user={user} data={graphs.grammarCorrectness} />
              </div>
            </div>
            }
      
            <div class="pe-4 col-6">
              { !!progress.weakestWords.length &&
              <>
                { renderProfTable('Weakest words', 'Word', progress.weakestWords) }
                <div class="row mt-3"><div class="col">
                  <Link to='/dictionary/words' class="float-end"><button class="btn btn-action btn-outline-secondary px-4">See all words</button></Link>
                </div></div>
              </>
              }
            </div>
            
            <div class="ps-4 col-6">
              { !!progress.weakestGrammars.length &&
              <>
                { renderProfTable('Weakest grammars', 'Grammar', progress.weakestGrammars) }
                <div class="row mt-3"><div class="col">
                  <Link to='/dictionary/grammars' class="float-end"><button class="btn btn-action btn-outline-secondary px-4">See all grammars</button></Link>
                </div></div>
              </>
              }
            </div>
            
          </div>    
        </div>
              
        <button class="btn btn-action btn-primary float-end mt-3" onClick={()=>{setShowMore(false)}}>See less</button>
        </>
      }
    </>
  );
}