import React from "react"; // need to include this to support the eval() below
import { Link } from "react-router-dom";

import ChinesePhrase from "./chineseWord.component";
import WordCard from "./wordCard.component";

import useVoice from "../hooks/voice.hook";

import { capitalizeFirstLetter, getSLUTypeName, formatEnglish, formatChinese,
  arrayDedupeInplace,
  getImagePath, getDictionaryPath,
  assert,
  getGrammarVisual,
} from '../common/utility';

const useRef = React.useRef;
const useEffect = React.useEffect;
const useState = React.useState;

export default function LessonCard({type, itemData, slu, showSLUType, showSLUCurrent, fullView, usedWordData, user}) {
  const exampleTableContainer = useRef();
  const exampleTable = useRef();
  const [etShowScroll, setETShowScroll] = useState(false);

  const voice = useVoice(user);

  // eslint-disable-next-line
  useEffect(() => {
    if(exampleTableContainer?.current && exampleTable?.current) {
      const showScroll = exampleTable.current.clientWidth > exampleTableContainer.current.clientWidth;
      if(showScroll !== etShowScroll) {
        setETShowScroll(showScroll); // react warning ignored above is: this line might produce inifinite update chain
      }
    }
  }); // run every time

  var cardJsx;

  function getPlayAudioFunc(wordIds) {
    return ()=>{
      const characters = wordIds.map(wordId=>usedWordData[wordId].characters).join('');
      if(characters) {
        voice.speak(characters);
      }
    };
  }

  function getProfVisual(item) {
    return (
      <div class="row">
        <div class="col-md-8 pe-4">
          <div class="text-center panel-inset p-1 h-100">
            <div class="fs-1 mt-2 fw-medium">{item.lastSeenStr}</div>
            <div class="text-muted mb-3">Last seen</div>
          </div>
        </div>
        <div class="col-md-4 ps-4">
          <div class="text-center panel-inset p-1 h-100">
            <div class="fs-1 mt-2 fw-medium">{item.lastRights} / {Math.min(3, item.seen)}</div>
            <div class="text-muted mb-3">Recent record</div>
          </div>
        </div>                  
        <div class="col-md-12 mt-4">
          <div class=" panel-inset p-3 h-100">
            <div class="row">
              <div class="col fs-4 text-muted">
                <span>Accuracy</span>
                <span class="badge bg-success float-end">{Math.ceil(100/item.seen*item.correct)}%</span>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <div className="progress mt-3 mb-2" role="progressbar">
                  <div className="progress-bar bg-info" style={{width:`${100/item.seen*item.correct}%`}}></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>  
    );
  }
  
  // prepare context for description rendering functions
  window.React = React; // HACK - eval's scope is window, not this component, so we have to load the required context into window
  function reactChinesePhrase(wordIds, forceStage) {
    return <ChinesePhrase words={wordIds.map(wordId=>usedWordData[wordId])} showDefs={true} capitalize={true} forceStage={forceStage} user={user} />;
  }
  function getWordForCard(wordId, posId=null) {
    const word = usedWordData[wordId];
    var defIndex = 0;
    if(type==='pos') {
      assert(posId);
      defIndex = word.defs.findIndex(d=>d.pos===posId); // which def has the new POS's id
    }
    word.english = word.defs[defIndex].english;
    word.pos = word.defs[defIndex].pos;
    word.chinese = [word];
    return word;
  }
  function reactWordCards(wordIds) {
    const wordCardsJsx = wordIds.map(wordId=>
      <WordCard {...{
        user, word:getWordForCard(wordId),
        colSize:12,
        linkDisabled:true,
      }} />
    );
    return (
      <div class="row w-50 mb-3">
        {wordCardsJsx}
      </div>
    );
  }
  function reactImage(imagePath) {
    const imageType = imagePath.split('/')[0].slice(0,-1);
    const imageName = imagePath.split('/')[1];
    return (
    <div class="panel-inset p-3 w-75 my-4 mx-auto">
      <img class="w-100" src={getImagePath(imageType, imageName)} alt="" />
    </div>
    );
  }
  window.reactChinesePhrase = reactChinesePhrase;
  window.reactWordCards = reactWordCards;
  window.reactImage = reactImage;
  
  // render
  switch(type) {
  case 'word':
    const word = itemData;
    const defs = [];
    word.defs?.forEach(def=>{
      const defText = capitalizeFirstLetter(formatEnglish(def.englishFull, user, def.pos));
      if(!defs.includes(defText))
        defs.push(defText);
    });
    cardJsx = (
    <>
      <h1>
        {formatChinese(word, user).trim()} 
        <i class="btn-action d-inline-block bi bi-play-circle-fill text-primary ms-2" style={{cursor:'pointer'}} onClick={getPlayAudioFunc([word.wordId])}></i>
      </h1>
      <div class="row">
        <div class={`col-${!!word.emoji?'9':'12'}`}>
          <div class="fst-italic">{arrayDedupeInplace(word.defs.map(d=>d.posName)).join(', ')}</div>
          { word.topic &&
            <div>Topic: {word.topic}</div>
          }
          <ol class="mt-3 fs-3">
            { defs.map((def, index) => (
              <li key={index}>{def}</li>
            ))}
          </ol>
        </div>
        { word.emoji &&
          <div class="col-2 position-relative">
            <span class={`position-absolute top-50 start-0 translate-middle-y mt-2`} style={{'font-size':'100px'}}>{word.emoji}</span>
          </div>              
        }
      </div>
      { !!word.relatedConcepts &&
        <div class="row mt-4">
          { word.relatedConcepts.map(concept=>
            <Link to={getDictionaryPath('concept', concept.id)} style={{textDecoration:'inherit', color:'inherit'}}>
              <div class="panel-inset fs-4 px-4 py-2">
                <span class={`me-3`}>💡</span>
                <span class="text-black">{concept.name}</span>
              </div>
            </Link>
          )}
        </div>
      }
      { !!word.seen && fullView &&
        <>
        <hr class="mt-4"/>
        <div class="mt-4">
          { getProfVisual(word) }
        </div>
        </>
      }
    </>
    );
    break;
  case 'grammar':
    const grammar = itemData;
    cardJsx = (
    <>
      <h1>{grammar.name}</h1>
      <div class="mt-3 text-muted fs-4 mb-4">{grammar.descLong ?? grammar.description}</div>
      <div class="row mt-3">
        { getGrammarVisual(grammar, {fullView}) }
      </div>
      <div class="mt-3">
        <h4 class="mb-3 text-muted">A few examples:</h4>
        
        <div class="w-100 overflow-x-scroll" ref={exampleTableContainer}>
          <table class="table fs-5" ref={exampleTable}>
            <thead>
              <tr>
                <th scope="col"></th>
                { grammar.boxes.map(boxTitle=>
                <th scope="col"><span class="text-muted">{boxTitle}</span></th>
                ) }
                <th scope="col"></th>
              </tr>
            </thead>
            <tbody class="table-group-divider">
              { grammar.examples.map(example=>
              <tr>
                <td><i class="btn-action d-inline-block bi bi-play-circle-fill text-primary" style={{cursor:'pointer'}} onClick={getPlayAudioFunc(example.chineseWords.map(gw=>gw.wordId))}></i></td>
                { grammar.boxes.map((boxTitle, boxIndex)=>
                <td><strong>{reactChinesePhrase(example.chineseWords.filter(gw=>gw.boxIndex===boxIndex).map(gw=>gw.wordId))}</strong></td>
                ) }
                <td>{formatEnglish(example.english, user)}</td>
              </tr>
              ) }
            </tbody>
          </table>
        </div>
        { etShowScroll && 
        <div class="row fs-6 text-muted">
          <div class="col-2">
            <i class="bi bi-arrow-left"></i>
          </div>
          <div class="col-8 text-center">
            Scroll to see entire table
          </div>
          <div class="col-2">
            <i class="bi bi-arrow-right float-end"></i>
          </div>
        </div>
        }

      </div>
      { !!grammar.seen && fullView &&
        <>
        <div class="mt-4">
          { getProfVisual(grammar) }
        </div>
        <div class="text-end mt-4">
          <Link to={`/practice/grammar/${grammar.id}`}>
            <button class="btn btn-action btn-primary">Practice this grammar</button>
          </Link>
        </div>
        </>
      }
    </>
    );
    break;
  case 'concept':
    const concept = itemData;
    // eslint-disable-next-line
    const conceptDesc = eval(concept.descLong);
    cardJsx = (
    <>
      <h1 class={(concept.id==='welcome')?'text-success':''}>{formatEnglish(concept.name, user)}</h1>
      <div class="mt-3 text-muted lh-lg">{conceptDesc}</div>
    </>
    );
    break;
  case 'theme':
  case 'pos':
    const item = itemData;
    cardJsx = (
    <>
      <h1>{item.name}</h1>
      <div class="mt-3 text-muted fs-4 mb-4">{item.description}</div>
      { (item.displayWords.length>1) &&
      <h4 class="text-muted">Some words you&apos;ll learn:</h4>
      }
      <div class="row mt-3">
        <div class="col-12">
          <div class="row">
            { item.displayWords.map(wordId=>(
              <WordCard {...{
                user, word:getWordForCard(wordId, item.id),
                colSize:6,
                linkDisabled:true,
              }} />
            )) }
          </div>
        </div>
        { /*item.emoji &&
        <div class="col-2">
          <div class="position-relative h-100">
            <span class="position-absolute top-50 start-50 translate-middle" style={{'font-size':'100px'}}>{item.emoji}</span>
          </div>              
        </div>              
        */ }
      </div>
    </>
    );
    break;
  case 'stage':
  case 'level':
    // eslint-disable-next-line
    const sluDesc = eval(slu.descLong);
    var sluIndexTitle = getSLUTypeName('stage')+' '+(user.stage+1);
    if(type==='level')
      sluIndexTitle += ', Level '+(user.level+1)
    cardJsx = (
    <>
      <p>
        <h1 class="mb-4">
          {sluIndexTitle + ' - '}
          {slu.name}
          { showSLUType && showSLUCurrent &&
          <span className={`badge text-bg-success float-end`} style={{marginTop:-2}}>Current {getSLUTypeName(type)}</span>
          }
        </h1>
      </p>
      <div class="mt-3 text-muted lh-lg">{sluDesc}</div>
      <div class="row mt-5">
        <div class="col-md-4 px-4">
          <div class="text-center panel-inset p-1">
            <div class="fs-1 mt-2 fw-medium">{slu.totalWords.toLocaleString()}</div>
            <div class="text-muted mb-3">Words</div>
          </div>
        </div>
        { !!slu.totalGrammars &&
        <div class="col-md-4 px-4">
          <div class="text-center panel-inset p-1">
            <div class="fs-1 mt-2 fw-medium">{slu.totalGrammars}</div>
            <div class="text-muted mb-3">Grammars</div>
          </div>
        </div>                  
        }
        { true &&
        <div class="col-md-4 px-4">
          <div class="text-center panel-inset p-1">
            <div class="fs-1 mt-2 fw-medium">{slu.numLessons}</div>
            <div class="text-muted mb-3">Lessons</div>
          </div>
        </div>                  
        }
      </div>
    </>
    );
    break;
  default:
    assert(false);
  }
    
  return cardJsx;
}
