import { Link } from "react-router-dom";

import ChinesePhrase from "../components/chineseWord.component";
import WordCard from "../components/wordCard.component";
import { getFluencyGraphData, Graph } from "../components/graph.component";

import UserDataService from "../services/user.service";

import {
  getDateStr,
  journeyItemHasContent,
} from './utility-common.js';
export * from './utility-common.js';

// links
export function getMapPath(stageIndex=null, levelIndex=null) {
  var path = '/map';
  if(stageIndex!==null) {
    path += `/stage-${stageIndex+1}`;
    if(levelIndex!==null) {
      path += `/level-${levelIndex+1}`;
    }
  }
  return path;
}
export function getImagePath(type, name) {
  return `/images/${type}s/${name}.png`;
}
export function getDictionaryPath(type, id) {
  return `/dictionary/${type}s/${id}`;
}

// arrays & objs
export function arrayIntersperse(arr, getSeparator) {
  if(!arr?.length)
    return arr;
  const lastElt = arr.pop();
  const out = [];
  arr.forEach(elt=>{
    out.push(elt);
    out.push(getSeparator());
  });
  out.push(lastElt);
  return out;
}

// streaks
export function getStreakDay(user, daysAgo=0) {
  return getDateStr(user, daysAgo);
}

// text rendering
export function formatEnglish(text, user, pos=null, isProper=null) {
  text = text.replaceAll('{userFName}', user.fname);
  text = text.replaceAll('{userfname}', user.fname); // HACK - because the def gets lowercased somewhere upstream
  text = text.replaceAll('[', '(');
  text = text.replaceAll(']', ')');
  if(pos) {
    if(posIsVerb(pos) && pos!=='verb-helper') {
      text = '(to) '+text;
    }
    else if(isProper) {
      text = capitalizeFirstLetter(text);
    }
    else if(text==='i') {
      text = 'I';
    }
  }
  return text;
}
export function formatChinese(word, user) {
  assert(word && user);
  var text;
  switch(user.stage) {
  case 0:
    text = word.toneless;
    break;
  case 1:
    text = word.tones;
    break;
  case 2:
    text = word.characters;
    break;
  default:
  }
  assert(text);
  return text;
}
export function capitalizeFirstLetter(string) {
  if(!string)
    return '';
  if(string.slice(0,5)==='(to) ')
    return string.slice(0,5) + string.charAt(5).toUpperCase() + string.slice(6);
  return string.charAt(0).toUpperCase() + string.slice(1);
}
export function unicodeSpaces(num=1) {
  return '\u00A0'.repeat(num);
}
export const disabledLinkStyles = {
  pointerEvents:'none',
  textDecoration:'inherit',
  color:'inherit',
};
export function getSLUTypeName(type) {
  return capitalizeFirstLetter(type);
}
export function showExtraTabs(user) {
  return user && (user.stage>0 || user.level>0 || user.lessonIndexActual>2);
}

// components
export function navLink({link, text, isNav, classes, activeClasses, currLocation, linkExactMatch=false}) {
  classes ??= '';
  if(linkExactMatch ? (currLocation === link) : currLocation?.startsWith(link))
    classes = activeClasses ?? `${classes} fw-semibold text-bg-light active `;
  if(!isNav)
    return (<Link to={link}><button class={`btn ${classes?classes:''}`} style={{outline:'none'}}>{text}</button></Link>);
  else
    return (<li class="nav-item"><Link to={link} class={`nav-link ${classes?classes:''}`} target={link.startsWith('http')?'_blank':''} style={{outline:'none'}}>{text}</Link></li>);
}
export function renderProfTable(title, type, list) {
  function getBarColor(item) {
    let color;
    if(item.ratio>0.9)
      color = 'success';
    else if(item.ratio>0.7)
      color = 'warning';
    else
      color = 'danger';
    return color;
  }
  function getBarPercent(item) {
    return Math.max(item.ratio, 0.1);
  }
  return (<>
    <h3 class="mb-4 fw-light">{title}</h3>
    <table class="table table-borderless mb-0 w-100 panel-inset" style={{tableLayout:'fixed'}}>
      <thead>
        <tr>
          <th scope="col" class="fw-semibold" style={{backgroundColor:'transparent', width:'auto'}}>{type}</th>
          <th scope="col" class="fw-semibold" style={{backgroundColor:'transparent', width:80}}># Seen</th>
          <th scope="col" class="fw-semibold" style={{backgroundColor:'transparent', width:110}}>Record</th>                
        </tr>
      </thead>
      <tbody>
        { list.map(item=>
        <tr>
          <td class="text-muted" style={{backgroundColor:'transparent', overflow:'hidden', textOverflow:'ellipsis'}}><Link to={getDictionaryPath(item.type, item.id)} class="link-secondary">{item.str}</Link></td>
          <td class="text-muted" style={{backgroundColor:'transparent'}}>{item.seen}</td>
          <td class="align-middle" style={{backgroundColor:'transparent'}}>
            <div className="progress" role="progressbar" style={{height:'12px'}}>
              <div className={`progress-bar bg-${getBarColor(item)}`} style={{width:`${getBarPercent(item)*100}%`}}></div>
            </div>                  
          </td>
        </tr>
        )}
      </tbody>
    </table>
  </>);
}
export function getGrammarVisual(grammar, {fullView=false, dropCb=null, dropItems=null}={}) {
  let ngvKey = 0, grammarBoxIndex = 0;
  const droppable = !!dropCb;
  function getGrammarVisualInner(parts, operatorType='parens') {
    let out = [];
    function getOperator(type) {
      let text = '';
      let fs = 60;
      let marginTop = 0;
      let scaleY = 1;
      switch(type) {
        case 'or':
          marginTop = 4;
          text = 'OR';
          fs = 24;
          break;
        case 'and':
          text = '+';
          fs = 50;
          break;
        case 'open':
          marginTop = -2;
          scaleY = 2;
          text = '(';
          break;
        case 'close':
          marginTop = -2;
          scaleY = 2;
          text = ')';
          break;
        default:
          assert(false);
      }
      return (
        <div class="position-relative grammar-operator" style={{'font-size':fs}} key={ngvKey++}>
          <span class="position-absolute top-50 start-50" style={{marginTop, transform:`translate(-50%,-50%) scaleY(${scaleY})`}}>{text}</span>
        </div>
      );
    }
    function allowDrop(ev) {
      if(droppable)
        ev.preventDefault();
    }
    function handleDrop(key) {
      return (ev)=>{
        ev.preventDefault();
        assert(dropCb);
        dropCb(key);
      };
    }
    parts.forEach(part=>{
      switch(part.type) {
      case 'grammar':
      case 'pos':
        const isGrammar = part.type==='grammar';
        const partName = isGrammar ? part.grammar.name : (part.pos.name + (!!part.tag ? ` (${capitalizeFirstLetter(part.tag)})` : ''));
        if(!partName)
          break;
        const partDesc = isGrammar ? part.grammar.description : part.pos.description;
        const isNew = grammar.partIndexesNew?.includes(part.topPartIndex);
        const isCaseSpecific = !isGrammar && part.pos.usedCaseSpecific;
        const dropItem = dropItems?.[grammarBoxIndex++];
        const baseHeaderColor = isNew ? 'success' : ((!part.isOptional && !isCaseSpecific) ? 'dark' : '');
        const headerColor = !!dropItem ? 
          ((dropItem.correct!==undefined) ? (dropItem.correct ? 'success' : 'danger') : baseHeaderColor) :
          baseHeaderColor;
        out.push(
          <div class="grammar-box" key={ngvKey++}>
            <div class={`card position-relative h-100 ${!!headerColor?`text-bg-${headerColor}`:''}`}>
              { isNew &&
                <span class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-dark">
                  New!
                </span>
              }
              <div class="card-header">
                <span>{partName}</span>
                { isGrammar && part.grammar.isTaught && fullView && 
                  <Link to={getDictionaryPath('grammar', part.id)}>
                    <span class="float-end text-info" style={{cursor:'pointer',transform:'scale(1.5)'}}><i class="bi bi-arrow-right-square-fill"></i></span>
                  </Link>
                }
              </div>
              <ul class="list-group list-group-flush h-100">
                <li class="list-group-item h-100" style={droppable?{minHeight:80}:{}} onDragOver={allowDrop} onDrop={handleDrop(grammarBoxIndex-1)}>
                  {!!partDesc && !droppable && 
                    <div class="mb-3">{partDesc}</div>
                  }
                  { dropItem &&
                  <div class="fs-4 p-2">
                    <div class="text-black"><ChinesePhrase words={dropItem.words} /></div>
                    { !!dropItem.english &&
                      <div class="text-muted"> → {dropItem.english}</div>
                    }
                  </div>
                  }
                </li>
              </ul>
              { part.isOptional &&
                <div class="card-footer">
                  <span class="fst-italic">Optional</span>
                </div>
              }
              { isCaseSpecific &&
                <div class="card-footer">
                  <span class="fst-italic">Case-specific</span>
                </div>
              }
            </div>
          </div>
        );
        break;
      case 'operator':
        var innerJsx = getGrammarVisualInner(part.parts, part.id);
        //console.log('finishing operator', part, innerJsx, operatorType)
        if(innerJsx.length>1 && parts.length>1 && part.id!==operatorType) { // if we aren't the only part on the level & we don't have the same operator as our parent
          innerJsx.unshift(getOperator('open'));
          innerJsx.push(getOperator('close'));
        }
        if(innerJsx.length)
          out.push(innerJsx);
        break;
      default:
        assert(false);
      }
    });
    switch(operatorType) {
      case 'or':
        out = arrayIntersperse(out, ()=>getOperator('or'));
        break;
      default:
        out = arrayIntersperse(out, ()=>getOperator('and'));
        break;
    }
    return out;
  }
  const out = getGrammarVisualInner(grammar.parts);
  return out;
}
export function renderJourneyItem(user, lesson) {
  if(!journeyItemHasContent(lesson))
    return;
  return (
    <div class="row">
      <div class="col-1 fs-3 text-muted fw-light text-center">
        <div class="row"><div class="col-12 p-2">
          <div class="pt-1">
            {lesson.index+1}
          </div>
        </div></div>
      </div>
      <div class="col-11 px-0"><div class="row">
        { lesson.themes.map(theme=>(
        <div class="col-6 p-2"><div class={`${lesson.isComplete ? 'panel-inset' : ''} fw-semibold fs-4 px-3 py-2`}>
          { theme.emoji &&
          <span class="me-2">{theme.emoji}</span>
          }
          { theme.id==='_CUSTOM_' ?
          <span class="">Personalized lesson</span>
          :
          <span class="">Theme - {theme.name}</span>
          }
        </div></div>
        )) }
      </div><div class="row">
        { lesson.grammars.map(grammar=>(
        <div class="col-12 p-2"><Link to={getDictionaryPath('grammar', grammar.id)} style={lesson.isComplete?{textDecoration:'inherit', color:'inherit'}:disabledLinkStyles}><div class={`${lesson.isComplete ? 'panel-inset' : ''} fs-4 px-3 py-2`}>
          <div class="fw-semibold">{grammar.name}</div>
          <div class="text-muted">{grammar.description}</div>
        </div></Link></div>
        )) }
        { lesson.words.map(word=>
        <WordCard {...{
          user, word,
          colSize:4,
          linkDisabled:!lesson.isComplete,
          whiteBox:lesson.isComplete,
          fontSize:4,
          includeChinese:false,
        }} />
        ) }              
      </div></div>
    </div>
  );
}
export function renderFluencySummary(user, fluencySummaryData) {
  const reviewChartData = getFluencyGraphData(user, user.streakHistory.map(so=>so.streak));
  
  return (
    <>
    { (fluencySummaryData.weakestWords.length>0) ?
    <div class="row mt-5">
      <div class="pe-3 col-md-6">
        { renderProfTable('Weakest words', 'Word', fluencySummaryData.weakestWords.slice(0,5)) }
      </div>

      <div class="ps-3 col-md-6">
        <Graph user={user} data={reviewChartData} numDays={14} />
      </div>
    </div>
    :
    <div class="mb-5">
      <Graph user={user} data={reviewChartData} />
    </div>
    }
    </>
  );
}

// nlp (can't really include these from BE)
export function posIsVerb(pos) {
  const verbs = [
    'verb',
    'verb-intrans',
    'verb-clause-object',
    'verb-helper',
    '是',
  ];
  return verbs.includes(getPosBasePart(pos));
}
function getPosBasePart(posName) {
  assert(posName);
  return posName.split('.')[0];
}

// user
export function saveUserPrefs(newData, setUser, done) {
  UserDataService.updatePrefs(newData)
  .then(result => {
    console.log('saved prefs', result);
    setUser(result.user);
    done();
  })
  .catch(e => {
    console.log(e);
  });        
}

// other
export function assert(isOk, msg) {
  if(!isOk)
    throw new Error(msg);
}
